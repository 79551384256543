<template>
  <v-card>
        <v-dialog :loading="dialogCreateLoading" persistent v-model="dialogCreate" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="dialogCreate = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{action}} Kierowcy</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-stepper v-model="currentStep"  vertical>
                
                  <v-stepper-step
                      :complete="currentStep > 1"
                      editable
                      step="1"
                  >
                      Podstawowe Dane Kierowcy
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                      :complete="currentStep > 2"
                      step="2"
                      editable
                  >
                      Adres
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step :complete="currentStep > 3" step="3" editable @click="currentStep=3">
                      Dane do Rozliczeń
                  </v-stepper-step>
                  <v-stepper-step :complete="currentStep > 4" step="4" editable @click="currentStep=4">
                      Dodatkowe Informacje
                  </v-stepper-step> 
                  <v-stepper-step :complete="currentStep > 5" step="5" editable>
                      Aplikacje Kierowcy
                  </v-stepper-step>   
                  <v-stepper-step :complete="currentStep > 6" step="6" editable>
                      Dane o Komorniku
                  </v-stepper-step> 
                  <v-stepper-step :complete="currentStep > 7" step="7" editable>
                      Podsumowanie
                  </v-stepper-step>                                                                
                

                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field label="Imię" v-model="firstName" required placeholder="Imię"
                            :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field label="Nazwisko" v-model="lastName" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                            placeholder="Nazwisko"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field label="Telefon" v-model="phone" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                            placeholder="Telefon"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field label="E-mail" :rules="[(v) => !!v || 'Uzupełnij Pole']" required type="email" v-model="email"
                            placeholder="E-mail"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field label="Hasło" :rules="[(v) => !!v || 'Uzupełnij Pole']" required type="password" v-model="password"
                            placeholder="Hasło"></v-text-field>
                        </v-col>                                                
                      </v-row>
                      <v-btn
                      color="primary"
                      @click="saveStep(currentStep)"
                      >
                      Dalej
                      </v-btn>

                      <v-btn text  @click="previousStep(currentStep)">
                          Poprzedni Krok
                      </v-btn>                      
                    </v-stepper-content>
                    <v-stepper-content step="2">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field label="Adres" v-model="street" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                            placeholder="Adres"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field label="Adres 2" v-model="streetExtraInfo" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                            placeholder="Adres 2">
                          </v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field label="Miasto" v-model="city" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                            placeholder="Miasto">
                          </v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-select label="Oddział" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="departament"
                            :items="departaments" item-text="departamentName" item-value="id"></v-select>
                        </v-col>
                      </v-row>
                      <v-btn
                      color="primary"
                      @click="saveStep(currentStep)"
                      >
                      Dalej
                      </v-btn>

                      <v-btn text  @click="previousStep(currentStep)">
                          Poprzedni Krok
                      </v-btn>                      
                    </v-stepper-content>  
                    <v-stepper-content step="3">
                      <v-row>
                        <v-col cols="2">
                          <v-checkbox label="Konto za Granicą?" v-model="abroadBankAccount"></v-checkbox>
                        </v-col>
                        <v-col cols="2">
                          <v-checkbox label="Wypłaty Gotówką" v-model="cashPayments"></v-checkbox>
                        </v-col>
                        <v-col cols="8">
                          <v-text-field label="Numer Konta" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="accountNo">
                          </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-checkbox label="Student" v-model="isStudent"></v-checkbox>
                        </v-col>                        
                        <v-col cols="3">
                          <v-select label="Częstotliwość Wypłat" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="paymentSchedule"
                            :items="paymentsSchedules" item-text="text" item-value="id"></v-select>
                        </v-col>   
                        <v-col cols="3">
                          <v-select label="Rodzaj Współpracy" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="cooperationType"
                            :items="cooperationTypes" item-text="text" item-value="id"></v-select>
                        </v-col>   
                        <v-col cols="3">
                            <v-select label="Rodzaj Zus" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="zusType"
                            :items="zusTypes" item-text="text" item-value="id"></v-select>
                        </v-col> 
                        <v-col cols="12">
                          <h2>Do starych obliczeń - w nowych nieużywane</h2>                                 
                        </v-col>                         
                        <v-col cols="6">
                          <v-checkbox label="Poniżej 26 Roku Życia?" v-model="isUnder26"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                          <v-checkbox label="Zatrudniony" v-model="isEmployed"></v-checkbox>
                        </v-col>
                                     
                      </v-row> 
                      <v-btn
                      color="primary"
                      @click="saveStep(currentStep)"
                      >
                      Dalej
                      </v-btn>

                      <v-btn text  @click="previousStep(currentStep)">
                          Poprzedni Krok
                      </v-btn>                                                           
                    </v-stepper-content>
                    <v-stepper-content step="4">
                      <v-row>
                      <v-col cols="4">
                          <v-text-field label="Numer Boczny" placeholder="Numer Boczny" v-model="licenceNumber"></v-text-field>
                        </v-col>
                      <v-col cols="4">
                        <v-text-field label="Marka Auta" v-model="carMake">
                        </v-text-field>
                       </v-col>
                      <v-col cols="4">
                        <v-text-field label="Tablica Rejestracyjna Auta" v-model="carPlate">
                        </v-text-field>
                      </v-col>
                      </v-row>
                      <v-btn
                      color="primary"
                      @click="saveStep(currentStep)"
                      >
                      Dalej
                      </v-btn>

                      <v-btn text  @click="previousStep(currentStep)">
                          Poprzedni Krok
                      </v-btn>                      
                    </v-stepper-content>   
                    <v-stepper-content step="5">
                      <v-row>
                      <v-col cols="4">
                        <v-text-field label="ID Bolt" placeholder="ID Bolt" v-model="boltId"></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="ID Uber" placeholder="ID Uber" v-model="uberId"></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="ID FreeNow" placeholder="ID FreeNow" v-model="freeId"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="ID UberEats" placeholder="ID UberEats" v-model="uberEatsId"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="ID Deligo" placeholder="ID Deligo" v-model="deligoId"></v-text-field>
                      </v-col>
                      </v-row>
                      <v-btn
                      color="primary"
                      @click="saveStep(currentStep)"
                      >
                      Dalej
                      </v-btn>

                      <v-btn text  @click="previousStep(currentStep)">
                          Poprzedni Krok
                      </v-btn>                      
                    </v-stepper-content> 
                     <v-stepper-content step="6">
                      <v-row>              
                          <v-col cols="2">
                            <v-checkbox label="Komornik" v-model="isReposession"></v-checkbox>
                          </v-col>
                          <v-col cols="5">
                            <v-text-field label="Nr. Konta Komornika" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="reposessionAccountNo">
                            </v-text-field>
                          </v-col>
                        <v-col cols="5">
                          <v-text-field label="Tutuł przelewu - komornik" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="reposessionWireTitle">
                          </v-text-field>
                        </v-col>
                          <v-col cols="12">
                            <v-text-field label="Przelew Komornik Linia 1" v-model="reposessionAddressLine1" required
                              placeholder="Przelew Komornik Linia 1"></v-text-field>
                          </v-col>  
                          <v-col cols="12">
                            <v-text-field label="Przelew Komornik Linia 2" v-model="reposessionAddressLine2" required
                              placeholder="Przelew Komornik Linia 2"></v-text-field>
                          </v-col>  
                          <v-col cols="12">
                            <v-text-field label="Przelew Komornik Linia 3" v-model="reposessionAddressLine3" required
                              placeholder="Przelew Komornik Linia 3"></v-text-field>
                          </v-col>  
                          <v-col cols="12">
                              <v-text-field label="Przelew Komornik Linia 4" v-model="reposessionAddressLine4" required
                                placeholder="Przelew Komornik Linia 4"></v-text-field>
                          </v-col>                                                                                            
                      </v-row> 
                      <v-btn
                      color="primary"
                      @click="saveStep(currentStep)"
                      >
                      Dalej
                      </v-btn>

                      <v-btn text  @click="previousStep(currentStep)">
                          Poprzedni Krok
                      </v-btn>                                           
                     </v-stepper-content>   
                     <v-stepper-content step="7">
                  <v-simple-table>
                                          <template v-slot:default>
                                          <thead>
                                              <tr>
                                              <th class="text-left">
                                                  Nazw Pola
                                              </th>
                                              <th class="text-left">
                                                  Wartość
                                              </th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              <tr>
                                                  <td>Imię</td>
                                                  <td>{{ firstName.trim().length > 0 ? '✅' +firstName : '❌ Uzupełnij Imię' }}</td>
                                              </tr>
                                              <tr>
                                                  <td>Nazwisko</td>
                                                  <td>{{ lastName.trim().length > 0 ? '✅' +lastName : '❌ Uzupełnij Nazwisko' }}</td>
                                              </tr>
                                              <tr>
                                                  <td>Email</td>
                                                  <td>{{ email.trim().length > 0 ? '✅' +email : '❌ Uzupełnij Email' }}</td>
                                              </tr>                                                        
                                              <tr>
                                                  <td>Telefon</td>                                                  
                                                  <td>{{ phone.trim().length > 0 ? '✅' +phone : '❌ Uzupełnij Telefon' }}</td>
                                              </tr>
                                              <tr>
                                                  <td>Nowe Hasło</td>
                                                  <td>{{ password?.trim().length > 0 ? '✅' +password : 'poprzednie hasło' }}</td>
                                              </tr>
                                              <tr>
                                                  <td>Adres</td>                                                  
                                                  <td>{{ street.trim().length > 0 ? '✅' +street : '❌ Uzupełnij Adres' }}</td>
                                              </tr>
                                              <tr>
                                                  <td>Adres 2</td>
                                                  <td>{{ streetExtraInfo.trim().length >0 ? '✅' +streetExtraInfo : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }}</td>
                                              </tr>  
                                              <tr>
                                                  <td>Miasto</td>                                                  
                                                  <td>{{ city.trim().length > 0 ? '✅' +city : '❌ Uzupełnij Miasto' }}</td>                                                  
                                              </tr>
                                              <tr>
                                                  <td>Oddział</td>
                                                  <td>{{ (departaments.find(it => it.id === departament))?.departamentName ? '✅' +(departaments.find(it => it.id === departament))?.departamentName : '❌ Nie wybrano' }}</td>
                                              </tr>
                                            <tr>
                                                  <td>Numer Konta</td>                                                  
                                                  <td>{{ !cashPayments ? (accountNo.trim().length == 26 ? '✅' +accountNo : '❌ Niepoprawny numer konta!') : '✅' +'N/D - gotówka' }}</td>                                                  
                                              </tr> 
                                            <tr>
                                                  <td>Konto za Granicą?</td>
                                                  <td>{{ abroadBankAccount ? 'Tak' : 'Nie' }}</td>
                                              </tr>
                                            <tr>
                                                  <td>Rodzaj Współpracy</td>
                                                  <td>{{ (cooperationTypes.find(it => it.id === cooperationType))?.text ? '✅' +(cooperationTypes.find(it => it.id === cooperationType))?.text : '❌ Nie wybrano' }}</td>
                                              </tr> 
                                            <tr>
                                                  <td>Częstotliwość Rozliczeń</td>
                                                  <td>{{(paymentsSchedules.find(it => it.id === paymentSchedule))?.text ? '✅' + (paymentsSchedules.find(it => it.id === paymentSchedule))?.text: '❌ Nie wybrano' }}</td>
                                              </tr>
                                            <tr>
                                                  <td>Rodzaj Zus</td>
                                                  <td> {{ (zusTypes.find(it => it.id === zusType))?.text ? '✅' + (zusTypes.find(it => it.id === zusType))?.text: '❌ Nie wybrano'}}</td>
                                              </tr> 
                                            <tr>
                                                  <td>Rodzaj Rozliczeń</td>
                                                  <td>{{ cashPayments ?  'Gotówka' : 'Przelew' }}</td>
                                              </tr> 
                                            <tr>
                                                  <td>Numer Boczny</td>
                                                  <td>{{ licenceNumber.trim().length >0 ? '✅' +licenceNumber : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }}</td>
                                              </tr>                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                                            <tr>
                                                  <td>Marka auta</td>
                                                  <td>{{ carMake.trim().length >0 ? '✅' +carMake : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }} </td>
                                              </tr> 
                                            <tr>
                                                  <td>Numer rejestracyjny</td>
                                                  <td>{{ carPlate.trim().length >0 ? '✅' +carPlate : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }}</td>
                                              </tr>                                                
                                        <tr>
                                                  <td>Id Bolt</td>                                                  
                                                  <td>{{ boltId.trim().length >0 ? '✅' +boltId : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }}</td>
                                              </tr>                                              
                                            <tr>
                                                  <td>Id Uber</td>                                                  
                                                  <td>{{ uberId.trim().length >0 ? '✅' +uberId : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }}</td>
                                              </tr>
    
                                            <tr>
                                                  <td>Id FreeNow</td>                                                  
                                                  <td>{{ freeId.trim().length >0 ? '✅' +freeId : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }}</td>
                                              </tr>                                               
                                            <tr>
                                                  <td>Id UberEats</td>
                                                  <td>{{ uberEatsId.trim().length >0 ? '✅' +uberEatsId : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }}</td>
                                              </tr> 
                                            <tr>
                                                  <td>Id Deligo</td>                                                  
                                                  <td>{{ deligoId.trim().length >0 ? '✅' +deligoId : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }}</td>
                                              </tr> 

                                            <tr>
                                                  <td>Komornik?</td>
                                                  <td>{{ isReposession ? 'Tak' : 'Nie' }}</td>
                                              </tr> 
                                            <tr>
                                                  <td>Dane do Przelewu do komornika</td>
                                                  <td>{{ isReposession ? (reposessionAccountNo.trim().length>0 ? '✅' +reposessionAccountNo : '❌ Niepoprawny numer konta!') : '✅' +'N/D - brak komornika' }}</td>
                                              </tr>                                                
                                              <tr>
                                                  <td>Tytuł Przelewu do komornika</td>
                                                  <td>{{ isReposession ? (reposessionWireTitle.trim().length>0 ? '✅' +reposessionWireTitle : '❌ Podaj tytuł!') : '✅' +'N/D - brak komornika' }}</td>
                                              </tr> 
                                              <tr>
                                                  <td>Przelew Komornik Linia 1</td>
                                                  <td>{{isReposession ? (reposessionAddressLine1.trim().length>0 ? '✅' +reposessionAddressLine1 : '❌ Podaj chociaż jedną linię adresu!') : '✅' +'N/D - brak komornika' }}</td>
                                              </tr> 
                                              <tr>
                                                  <td>Przelew Komornik Linia 2</td>
                                                  <td>{{isReposession? reposessionAddressLine2: '✅' +'N/D - brak komornika' }}</td>
                                              </tr> 
                                              <tr>
                                                  <td>Przelew Komornik Linia 3</td>
                                                  <td>{{ isReposession ? reposessionAddressLine3: '✅' +'N/D - brak komornika' }}</td>
                                              </tr>
                                              <tr>
                                                  <td>Przelew Komornik Linia 4</td>
                                                  <td>{{ isReposession ? reposessionAddressLine4: '✅' +'N/D - brak komornika' }}</td>
                                              </tr>                                                                                                                                                                                         
                                                                                                                                                                                                                                                            
                                          </tbody>
                                          </template>                        
                                      </v-simple-table>

                                                          <v-btn
                                      color="primary"
                                      @click="createDriver()"
                                      >
                                      Zapisz
                                      </v-btn>
                                      <v-btn text  @click="previousStep(currentStep)">
                                          Poprzedni Krok
                                      </v-btn>                      
                     </v-stepper-content>                                 
                  </v-stepper-items>
                </v-stepper>                
              </v-container>
            </v-card-text>
            <!-- <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogCreate = false">
                Anuluj
              </v-btn>
              <v-btn color="blue darken-1" text @click="createDriver()">
                Zapisz
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-dialog>
        <v-dialog :retain-focus="false"  v-model="dialogBonuses" persistent max-width="600px">
          <v-card>
        <v-card-title>
          <span class="text-h5">Dodawanie Bonusu</span>
        </v-card-title>
        <v-card-text>
            <v-from>
              <v-row>
              <v-col cols="12">
                <v-text-field label="Kwota" v-model="priceBonus" placeholder="Kwota" :rules="[(v) => !!v || 'Uzupełnij Pole']">
                </v-text-field>
              </v-col>
                  <v-col cols="12">
                    <v-text-field label="Opis" v-model="descriptionBonus" placeholder="Opis"
                      :rules="[(v) => !!v || 'Uzupełnij Pole']">
                    </v-text-field>
                  </v-col>
              <v-col cols="12">
                <v-select label="Rodzaj" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="balanceType" :items="balanceTypes"
                  item-text="text" item-value="id"></v-select>
              </v-col>
                <v-col cols="12">
                  <v-text-field v-model="bonusWeek" label="Tydzień Rozliczeniowy" type="week"></v-text-field>
                </v-col>
              </v-row>
            </v-from>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogBonuses = false">
                Anuluj
              </v-btn>
              <v-btn color="blue darken-1" text @click="createBonus()">
                Zapisz
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog :retain-focus="false" v-model="dialogInvoiceCost" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Dodawanie Faktury Kosztowej</span>
            </v-card-title>
            <v-card-text>
              <v-from>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Kwota Brutto na Fakturze" v-model="invoiceCostPrice" placeholder="Kwota Brutto na Fakturze"
                      :rules="[(v) => !!v || 'Uzupełnij Pole']">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="Opis" v-model="invoiceCostDescription" placeholder="Opis"
                      :rules="[(v) => !!v || 'Uzupełnij Pole']">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select label="% Zwrotu" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="taxType"
                      :items="taxTypes" item-text="text" item-value="id"></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="Tydzień Rozliczeniowy" v-model="invoiceWeekStr" type="week"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input accept="application/pdf" v-model="invoiceCostFile" type="file" refs="file" label="Załącznik"></v-file-input>
                  </v-col>
                </v-row>
              </v-from>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogInvoiceCost = false">
                Anuluj
              </v-btn>
              <v-btn color="blue darken-1" text @click="createCostInvoice()">
                Zapisz
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog :retain-focus="false" v-model="dialogContracts" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Dodawanie Dokumentu</span>
            </v-card-title>
            <v-card-text>
              <v-from>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Opis" v-model="descriptionContract" required placeholder="Opis"
                      :rules="[(v) => !!v || 'Uzupełnij Pole']">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                      <v-select label="Rodzaj Dokumentu" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="contractType" :items="contractTypes"
                        item-text="text" item-value="id"></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input accept="application/pdf" v-model="contractFile" type="file" refs="file" label="Umowa"></v-file-input>
                  </v-col>
                </v-row>
              </v-from>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogContracts = false">
                Anuluj
              </v-btn>
              <v-btn color="blue darken-1" text @click="createContract()">
                Zapisz
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
<v-dialog v-model="pitDialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="pitDialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Formularz Pit</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field label="Imię Kierowcy" v-model="pitDriverFirstName"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Nazwisko Kierowcy" v-model="pitDriverLastName"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Email Kierowcy" v-model="pitDriverEmail"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-checkbox label="Wyrażam zgodę na przekazywanie mi przez firmę BTP Sp. z o.o. PIT-ów drogą elektroniczną na wskazany przeze mnie poniżej adres e-mailowy" v-model="pitCheckbox1"></v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-checkbox label="Nie wyrażam zgody na przekazywanie przez firmę BTP Sp. z o.o. PIT-ów drogą elektroniczną. Proszę o wydawanie PIT-ów do rąk własnych / wysłanie listem poleconym." v-model="pitCheckbox2"></v-checkbox>
        </v-col>       
      </v-row>
    </v-container>
    <v-card-actions class="text-center">
      <v-container>
        <v-btn class="primary" @click="pitDialog = false">Anuluj</v-btn>
        <v-btn class="warning" @click="downloadDocument('pit')">Pobierz</v-btn>
        <v-btn class="success"  @click="updateDocument('pit')">Zapisz</v-btn>
      </v-container>
    </v-card-actions>
    </v-card>   

</v-dialog>
<v-dialog v-model="statementDialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card>
    <v-toolbar dark color="primary">
                <v-btn icon dark @click="statementDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Formularz Oświadczenie</v-toolbar-title>
                <v-spacer></v-spacer>
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field label="Nazwisko Kierowcy" v-model="statementDriverLastName"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Nazwisko Rodowe Kierowcy" v-model="statementDriverOryginalLastName"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Pierwsze Imię Kierowcy" v-model="statementDriverFirstName"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Drugie Imię Kierowcy" v-model="statementDriverSecondName"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Data Urodzenia" v-model="statementDriverBirth"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Pesel" v-model="statementDriverDocId"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Inny Dokument" v-model="statementDriverDocIdOther"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Gmina" v-model="statementDriverDistrict"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Województwo" v-model="statementDriverState"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Ulica" v-model="statementDriverStreet"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Numer Domu" v-model="statementDriverHome"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Numer Mieszkania" v-model="statementDriverHome2"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Kod Pocztowy" v-model="statementDriverPostalCode"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Miejscowość" v-model="statementDriverCity"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Kraj" v-model="statementDriverCountry"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Obywatelstwo" v-model="statementDriverCitizenship"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Inna Gmina" v-model="statementOtherDriverDistrict"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Inne Województwo" v-model="statementOtherDriverState"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Inna Ulica" v-model="statementOtherDriverStreet"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Inny Numer Domu" v-model="statementOtherDriverHome"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Inny Numer Mieszkania" v-model="statementOtherDriverHome2"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Inny Kod Pocztowy" v-model="statementOtherDriverPostalCode"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Inna Miejscowość" v-model="statementOtherDriverCity"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Inny Kraj" v-model="statementOtherDriverCountry"></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-checkbox label="Nie jestem zatrudniony/a na podstawie umowy o pracę w innym zakładzie pracy" v-model="statementCheckbox1"></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-checkbox label="Jestem zatrudniony/a na podstawie umowy o pracę w innym zakładzie pracy"
            v-model="statementCheckbox2"></v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Nazwa Zakładu Pracy" v-model="work"></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-checkbox label="Osiągam dochód wyższy niż minimalne wynagrodzenie"
            v-model="statementCheckbox3"></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-checkbox label="Osiągam dochód niższy niż minimalne wynagrodzenie"
            v-model="statementCheckbox4"></v-checkbox>
        </v-col>
        <v-col cols="4">
          <v-checkbox label="Wykonuję w innych zakładach pracy umowę zlecenia okres obowiązywania umowy zlecenia" v-model="statementCheckbox5"></v-checkbox>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Od Dnia" v-model="statementDate1"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Do Dnia" v-model="statementDate2"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-checkbox label="Nie wykonuje w innych zakładach pracy umowę zlecenia" v-model="statementCheckbox6"></v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-checkbox label="Prowadzę działalność gospodarczą" v-model="statementCheckbox7"></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-checkbox label="Z tytułu prowadzenia działalności gospodarczej opłacam składki na ubezpieczenie społeczne" v-model="statementCheckbox8"></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-checkbox label="Z tytułu prowadzenia działalności gospodarczej nie opłacam składki na ubezpieczenie społeczne" v-model="statementCheckbox9"></v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-checkbox label="Nie prowadzę działalności gospodarczej " v-model="statementCheckbox10"></v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-checkbox label="Jestem Uprawniony Do" v-model="statementCheckbox11"></v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-checkbox label="Emerytury" v-model="statementCheckbox12"></v-checkbox>
        </v-col>
        <v-col cols="4">
          <v-checkbox label="Renty" v-model="statementCheckbox13"></v-checkbox>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Ustalonej decyzją oddziału ZUS decyzja z dnia" v-model="statementZusDate1"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Znak Zus" v-model="statementZusDate2"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-checkbox label="Nie jestem uprawniony do emerytury/renty" v-model="statementCheckbox14"></v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-checkbox label="Posiadam orzeczony stopień niezdolności do pracy" v-model="statementCheckbox15"></v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-checkbox label="Nie posiadam orzeczony stopień niezdolności do pracy" v-model="statementCheckbox16"></v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-checkbox label="Nie jestem uczniem – studentem" v-model="statementCheckbox17"></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-checkbox label="jestem uczniem – studentem " v-model="statementCheckbox18"></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-text-field label="Nazwa Szkoły/Uczelni" v-model="university"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-checkbox label="Nie wnoszę o objęcie mnie dobrowolnym ubezpieczeniem chorobowym" v-model="statementCheckbox19"></v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-checkbox label="Wnoszę o objęcie mnie dobrowolnym ubezpieczeniem chorobowym" v-model="statementCheckbox20"></v-checkbox>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="text-center">
      <v-container>
        <v-btn class="primary" @click="statementDialog = false">Anuluj</v-btn>
        <v-btn class="warning" @click="downloadDocument('statement')">Pobierz</v-btn>
        <v-btn class="success" @click="updateDocument('statement')">Zapisz</v-btn>
      </v-container>
    </v-card-actions>
  </v-card>

</v-dialog>
<v-dialog v-model="rentDialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="rentDialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Formularz Wypożyczenie</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field label="Data Zawarcia Umowy" v-model="rentDate"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Imię Kierowcy" v-model="rentDriverFirstName"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Nazwisko Kierowcy" v-model="rentDriverLastName"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Dowód" v-model="rentDriverDoc"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Pesel" v-model="rentDriverId"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Miasto" v-model="rentDriverCity"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Ulica" v-model="rentDriverStreet"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Marka Samochodu" v-model="rentDriverCarMake"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Model Samochodu" v-model="rentDriverCarModel"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Tablica Rej." v-model="rentDriverCarPlate"></v-text-field>
        </v-col>
        <v-col cols="8">
          <v-text-field label="VIN" v-model="rentDriverCarVin"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Rok Prod." v-model="rentDriverCarYear"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Numer Konta" v-model="rentDriverAccNo"></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="text-center">
      <v-container>
        <v-btn class="primary" @click="rentDialog = false">Anuluj</v-btn>
        <v-btn class="warning" @click="downloadDocument('rent')">Pobierz</v-btn>
        <v-btn class="success" @click="updateDocument('rent')">Zapisz</v-btn>
      </v-container>
    </v-card-actions>
  </v-card>

</v-dialog>
<v-dialog v-model="dealDialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="dealDialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Formularz Umowa</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field label="Data Umowy" v-model="dealDate"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Miejscowosć Umowy" v-model="dealCity"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Imię Kierowcy" v-model="dealFirstName"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Nazwisko Kierowcy" v-model="dealLastName"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Miasto Kierowcy" v-model="dealDriverCity"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Ulica Kierowcy" v-model="dealDriverStreet"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Telefon Kierowcy" v-model="dealDriverPhone"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Dowód Kierowcy" v-model="dealDriverDoc"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Numer Konta Kierowcy" v-model="dealDriverAccountNo"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Marka Auta" v-model="dealDriverCarMake"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Model Auta" v-model="dealDriverCarModel"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Rejestracja Auta" v-model="dealDriverCarPlate"></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="text-center">
      <v-container>
        <v-btn class="primary" @click="dealDialog = false">Anuluj</v-btn>
        <v-btn class="warning" @click="downloadDocument('deal')">Pobierz</v-btn>
        <v-btn class="success" @click="updateDocument('deal')">Zapisz</v-btn>
      </v-container>
    </v-card-actions>
  </v-card>

</v-dialog>
  <v-dialog v-model="dealDeligoDialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dealDeligoDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Formularz Umowa Deligo</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field label="Data Umowy" v-model="deligoDate"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Miejscowosć Umowy" v-model="deligoCity"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Imię Kierowcy" v-model="deligoDriverFirstName"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Nazwisko Kierowcy" v-model="deligoDriverLastName"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Miasto Kierowcy" v-model="deligoDriverCity"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Ulica Kierowcy" v-model="deligoDriverStreet"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Telefon Kierowcy" v-model="deligoDriverPhone"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Dowód Kierowcy" v-model="deligoDriverId"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Numer Konta Kierowcy" v-model="deligoDriverAccNo"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field label="Marka Auta" v-model="deligoDriverCarMake"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field label="Model Auta" v-model="deligoDriverCarModel"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field label="Rejestracja Auta" v-model="deligoDriverCarPlate"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions class="text-center">
        <v-container>
          <v-btn class="primary" @click="dealDeligoDialog = false">Anuluj</v-btn>
          <v-btn class="warning" @click="downloadDocument('deligo')">Pobierz</v-btn>
          <v-btn class="success" @click="updateDocument('deligo')">Zapisz</v-btn>
        </v-container>
      </v-card-actions>
    </v-card>

  </v-dialog>
<v-dialog v-model="zusDialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="zusDialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Formularz Zus</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field label="Imię Kierowcy" v-model="zusFirstName"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Drugie Imię Kierowcy (o ile w dowodzie)" v-model="zusSecondName"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Nazwisko Kierowcy" v-model="zusLastName"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Imiona Rodziców" v-model="zusParentsName"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Pesel" v-model="zusDriverId"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Seria Dowodu" v-model="zusDocId"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Data Urodzenia" v-model="zusBirthDate"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Miejsce Urodzenia" v-model="zusBirthCity"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Nazwisko Rodowe" v-model="zusOryginalLastName"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Obywatelstwo" v-model="zusDriverCitizenship"></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-checkbox label="Kasa Chorych - ZUS" v-model="zusCheckbox1"></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-checkbox label="Kasa Chorych - KRUS" v-model="zusCheckbox2"></v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Urząd Skarbowy" v-model="zusTaxOffice"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Kod Pocztowy" v-model="zusPostalCode"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Miejscowość" v-model="zusDriverCity"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Gmina" v-model="zusDriverDistrict"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Ulica" v-model="zusDriverStreet"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Numer Domu" v-model="zusDriverStreet1"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Numer Mieszania" v-model="zusDriverStreet2"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Inny Kod Pocztowy" v-model="zusPostalOtherCode"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Inna Miejscowość" v-model="zusDriverOtherCity"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Inna Gmina" v-model="zusDriverOtherDistrict"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Inna Ulica" v-model="zusDriverOtherStreet"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Inny Numer Domu" v-model="zusDriverOtherStreet1"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Inny Numer Mieszania" v-model="zusDriverOtherStreet2"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-checkbox label="Nie ma ustalonego prawa do emerytury i renty" v-model="zusCheckbox3"></v-checkbox>
        </v-col>
        <v-col cols="4">
          <v-checkbox label="Ma ustalone prawo do emerytury" v-model="zusCheckbox4"></v-checkbox>
        </v-col>
        <v-col cols="4">
          <v-checkbox label="Ma ustalone prawo do renty" v-model="zusCheckbox5"></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox label="Nie posiada orzeczenia o stopniu niepełnosprawności" v-model="zusCheckbox6"></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox label="Ma orzeczenie o lekkim stopniu niepełnosprawności" v-model="zusCheckbox7"></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox label="Ma orzeczenie o umiarkowanym stopniu niepełnosprawności" v-model="zusCheckbox8"></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox label="Ma orzeczenie o znacznym stopniu niepełnosprawności" v-model="zusCheckbox9"></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-checkbox label="Ubezpieczenie Chorobowe - Tak" v-model="zusCheckbox10"></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-checkbox label="Ubezpieczenie Chorobowe - Nie" v-model="zusCheckbox11"></v-checkbox>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="text-center">
      <v-container>
        <v-btn class="primary" @click="zusDialog = false">Anuluj</v-btn>
        <v-btn class="warning" @click="downloadDocument('zus')">Pobierz</v-btn>
        <v-btn class="success" @click="updateDocument('zus')">Zapisz</v-btn>
      </v-container>
    </v-card-actions>
  </v-card>

</v-dialog>
<v-dialog v-model="dashboardDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
<v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="dashboardDialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Składowe Rozliczenia</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
  <v-card-text>
    <v-card>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Pozycja
            </th>
            <th class="text-left">
              Kwota
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in settlementDetailsTable"
            :key="item.name"
          >
            <td>{{ item.name }}</td>
            <td>{{ item.price }}</td>
          </tr>
        </tbody>
      </template>
      </v-simple-table>
      </v-card>
  </v-card-text>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="blue darken-1" text @click="dashboardDialog = false">
      Anuluj
    </v-btn>
  </v-card-actions>
</v-card>
</v-dialog>
        <v-dialog :retain-focus="false" v-model="smsDialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Wysyłanie SMS</span>
            </v-card-title>
            <v-card-text>              
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Treść" v-model="smsDescription" required placeholder="Treść"
                      :rules="[(v) => !!v || 'Uzupełnij Pole']">
                    </v-text-field>
                  </v-col>
                </v-row>              
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="smsDialog = false">
                Anuluj
              </v-btn>
              <v-btn color="blue darken-1" text @click="sendSms()">
                Zapisz
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <v-dialog v-model="dialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">        
          <v-toolbar flat color="primary" dark>
                                  <v-btn icon dark @click="dialog = false">
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
            <v-toolbar-title>{{name}}</v-toolbar-title>
          </v-toolbar>
          <v-tabs vertical style="height:100vh;">
            <v-tab>
              <v-icon left>
                mdi-gas-station
              </v-icon>
              Historia DKV
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-car-info
              </v-icon>
              Historia Aut
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-receipt-text
              </v-icon>
              Historia Wypłat
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-ticket-account
              </v-icon>
              Historia Kar
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-handshake
              </v-icon>
              Inne Dokumenty
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-receipt
              </v-icon>
              Faktury Kosztowe
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-message-processing
              </v-icon>
              SMS
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-handshake
              </v-icon>
              Wypełnianie Dokumentów
            </v-tab>
            <v-tab @click="showEdit()" >
              <v-icon left>
                mdi-cog
              </v-icon>
              Konfiguracja
            </v-tab>    
            <v-tab-item>
              <v-card flat>
                <v-data-table :headers="dkvHeaders" :items="dkv" :options.sync="dkvOptions"
                  :server-items-length="totalRecordsDkv" :loading="dkvLoading" class="elevation-1">
                </v-data-table>
              </v-card>
            </v-tab-item>
            <v-tab-item :eager="true">
                <v-card-title>
                  Historia Aut
                </v-card-title>
              <v-card flat>
                <v-data-table :headers="carHeaders" :items="carHistory" :options.sync="carOptions"
                  :server-items-length="totalRecordCar" :loading="carLoading" class="elevation-1">
                </v-data-table>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title>
                  Wypłaty                                    
                </v-card-title>
                <v-data-table :headers="invoiceHeaders" :items="invoices" :options.sync="invoiceOptions" :server-items-length="totalRecordsInvoice"
                  :loading="invoiceLoading" class="elevation-1">
                  <template v-slot:item.actions="{ item }">
                    <v-btn class="primary" @click="getDashboard(item.id)">
                      <v-icon small class="pt-1">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
            <v-tab-item :eager="true">
              <v-card flat>
                <v-card-title>
                  Obciążenia i Bonusy
                  <v-spacer></v-spacer>
                  <v-btn class="primary" @click="showDialog('bonuses')">Dodaj</v-btn>
                </v-card-title>
                <v-data-table :headers="bonusHeaders" :items="bonuses" :options.sync="bonusOptions" :server-items-length="totalRecordsBonus"
                  :loading="bonusLoading" class="elevation-1">
                  <template v-slot:item.actions="{ item }">
                    <v-btn class="error" @click="deleteBonus(item.id)">
                      <v-icon small class="pt-1">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item >
                  <v-tab-item :eager="true">
                    <v-card flat>
                      <v-card-title>
                        Dokumenty
                        <v-spacer></v-spacer>
                        <v-btn class="primary" @click="showDialog('contracts')">Dodaj</v-btn>
                      </v-card-title>
                      <v-data-table :headers="dealHeaders" :items="deals" :options.sync="dealOptions"
                        :server-items-length="totalRecordsDeal" :loading="contractLoading" class="elevation-1">
                        <template v-slot:item.actions="{ item }">
                          <v-btn class="primary" @click="showDeal(item.id)">
                            <v-icon small class="pt-1">mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.delActions="{ item }">
                          <v-btn class="error" @click="deleteContract(item.id)">
                            <v-icon small class="pt-1">mdi-delete</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title>
                  Faktury Kosztowe
                        <v-spacer></v-spacer>
                        <v-btn class="primary" @click="showDialog('invoiceCost')">Dodaj</v-btn>
                </v-card-title>
                <v-data-table :headers="costinvoiceHeaders" :items="costinvoices" :options.sync="costinvoiceOptions"
                  :server-items-length="totalRecordsInvoiceCost" :loading="invoiceLoadingCost" class="elevation-1">
                  <template v-slot:item.actions="{ item }">
                    <v-btn class="primary" @click="showInvoiceCost(item.id)">
                      <v-icon small class="pt-1">mdi-eye</v-icon>
                    </v-btn>
                    <v-btn class="error" @click="deleteInvoice(item.id)">
                      <v-icon small class="pt-1">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
            <v-tab-item :eager="true">
              <v-card flat>
                <v-card-title>
                  SMS
                  <v-spacer></v-spacer>
                  <v-btn class="primary" @click="showDialog('sms')">Wyślij SMS</v-btn>
                </v-card-title>
                <v-data-table :headers="smsHeaders" :items="smsHistory" :options.sync="smsOptions"
                  :server-items-length="totalRecordsSms" :loading="smsLoading" class="elevation-1">
                </v-data-table>
              </v-card>
            </v-tab-item>
            <v-tab-item :eager="true">
              <v-card flat>
                <v-card-title>
                  Wypełnianie Dokumentów
                  <v-spacer></v-spacer>                  
                </v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="6"> <v-btn style="width: 100%;" @click="showZusForm()" class="primary">Oświadczenie ZUS</v-btn></v-col>
                    <v-col cols="6"> <v-btn style="width: 100%;" @click="showPitForm()" class="primary">Oświadczenie PIT</v-btn></v-col>
                    <v-col cols="4"><v-btn style="width: 100%;" @click="showRentForm()"  class="primary">Umowa Najmu</v-btn></v-col>
                    <v-col cols="4"><v-btn style="width: 100%;" @click="showDealForm()" class="primary">Umowa Zlecenie</v-btn></v-col>
                    <v-col cols="4"><v-btn style="width: 100%;" @click="showDeligoForm()" class="primary">Umowa Zlecenie Deligo</v-btn></v-col>
                    <v-col cols="4"><v-btn style="width: 100%;" @click="showStatementForm()"  class="primary">Oświadczenie Zleceniobiorcy</v-btn></v-col>
                    <v-col cols="12"><v-btn style="width: 100%;" @click="downloadDocument('rodo')" class="primary">Rodo</v-btn></v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-tab-item>
          </v-tabs>
      </v-dialog>

    <v-card-title v-if="!showFull">
      Kierowcy
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Szukaj" single-line hide-details></v-text-field>
      <v-spacer></v-spacer>
      <v-btn class="primary" @click="showDialog()">Dodaj</v-btn>
    </v-card-title>
    <v-data-table v-if="!showFull" :headers="headers" :items="drivers" :options.sync="options" :server-items-length="totalRecords"
      :loading="loading" :search="search" class="elevation-1">
      <template v-slot:item.actions="{ item }">
        <v-btn class="primary" @click="getDriverById(item.id)">
          <v-icon small class="pt-1">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.actionsDel="{ item }">
        <v-btn class="error" @click="deleteDriver(item.id)">
          <v-icon small class="pt-1">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from 'axios'
// axios.interceptors.response.use(function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     console.log(response);
//     return response;
//   }, function (error) {
//     console.log(error);
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     return Promise.reject(error);
//   });
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
export default {
  props:["selectedDriver","showFull"],
  name: "DriverDetails",
  data: () => ({
    currentStep:1,
    isReposession: false,
    reposessionAccountNo: '',
    reposessionWireTitle: '',      
    carPlate: '',
    carMake: '',    
    totalCash:0,
    detailedBolt: [],
    detailedUber: [],
    detailedFreeNow: [],    
    headersBolt: [
      {
        text: 'Bolt Brutto',
        align: 'start',
        value: 'bolt_total',
        sortable: false,
      },
      {
        text: 'Bolt Gotówka',
        align: 'start',
        value: 'bolt_cashFee',
        sortable: false,
      },
      {
        text: 'Opłata Bolt',
        align: 'start',
        value: 'bolt_boltFee',
        sortable: false,
      },
      {
        text: 'Odwołania Bolt',
        align: 'start',
        value: 'bolt_cancelFee',
        sortable: false,
      },
      {
        text: 'Bonus Bolt',
        align: 'start',
        value: 'bolt_bonusFee',
        sortable: false,
      },
      {
        text: 'Zwroty Bolt',
        align: 'start',
        value: 'bolt_returnsFee',
        sortable: false,
      },
      {
        text: 'Napiwki Bolt',
        align: 'start',
        value: 'bolt_tipFee',
        sortable: false,
      },
    ],
    headersUber: [
      {
        text: 'Uber Brutto',
        align: 'start',
        value: 'uber_totalWithCash',
        sortable: false,
      },
      {
        text: 'Uber Gotówka',
        align: 'start',
        value: 'uber_cashFee',
        sortable: false,
      },
      {
        text: 'Opłata za Oczekiwanie',
        align: 'start',
        value: 'uber_waitingFee',
        sortable: false,
      },
      {
        text: 'Napiwki Uber',
        align: 'start',
        value: 'uber_tipFee',
        sortable: false,
      },
      {
        text: 'Odwołania Uber',
        align: 'start',
        value: 'uber_cancelFee',
        sortable: false,
      },
      {
        text: 'Uber Quest',
        align: 'start',
        value: 'uber_quest',
        sortable: false,
      },
      {
        text: 'Polecenia',
        align: 'start',
        value: 'uber_recomendationFee',
        sortable: false,
      },
    ],
    headersFree: [
      {
        text: 'FreeNow Brutto',
        align: 'start',
        value: 'freenow_tourTotal',
        sortable: false,
      },
      {
        text: 'FreeNow Gotówka',
        align: 'start',
        value: 'freenow_tourTotalCash',
        sortable: false,
      },
      {
        text: 'Napiwki',
        align: 'start',
        value: 'freenow_tip',
        sortable: false,
      },
      {
        text: 'Bonus',
        align: 'start',
        value: 'freenow_bonusBrutto',
        sortable: false,
      },
    ],     
    // create
    deligoId:'',
    search: '',
    password:null,
    street:'',
    dialogCreateLoading:false,
    streetExtraInfo:'',
    city:'',
    licenceNumber:'',
    abroadBankAccount:false,
    cashPayments:false,
    isUnder26:false,
    isStudent:false,
    isEmployed: false,
    accountNo:'',
    firstName:'',
    lastName:'',
    phone:'',
    email:'',
    paymentSchedule:'',
    cooperationType:'',    
    boltId:'',
    uberId:'',
    uberEatsId:'',
    freeId:'',    
    departaments:[],
    name:'Nazwa Kierowcy',
    action:'Dodawanie',
    departament: '',
    contractType:'other',
    reposessionAddressLine1:'',
    reposessionAddressLine2:'',
    reposessionAddressLine3:'',
    reposessionAddressLine4:'',
    zusType: '',
    contractTypes:[
      {
      id:'other',
      text:'Inne'
    }, {
        id: 'auto',
        text: 'Dowód Rejestracyjny'
      },
      {
        id: 'license',
        text: 'Prawo Jazdy'
      },
      {
        id: 'medical',
        text: 'Badania Medyczne'
      },
      {
        id: 'psycho',
        text: 'Psychotesty'
      }, {
        id: 'oc',
        text: 'Ubezpieczenie'
      }],
    paymentsSchedules: [{
      id: 'week',
      text: 'Tygodniowy'
    },
    {
      id: 'two_week',
      text: '2 Tygodnie'
      }, {
      id: 'month',
      text: 'Miesiąc'
      }],  
      zusTypes:[
        {
          text: 'Pracownik bez zatrudnienia',
          id: 'no_employed'
        },
        {
          text: 'Pracownik zatrudniony w innej firmie z wynagrodzeniem poniżej najniższej krajowej',
          id: 'employed_below'
        },
        {
          text: 'Pracownik zatrudniony w innej firmie z wynagrodzeniem powyżej lub równej najniższej krajowej',
          id: 'employed_above'
        },
        {
          text: 'Pracownik poniżej 26 roku życia',
          id: 'under_26'
        },                        
      ],
      cooperationTypes:[ {
          id: 'b2b_vat',
          text: 'B2B z VAT'
        },
        {
          id: 'b2b_no_vat',
          text: 'B2B Bez VAT'
        },
        {
          id: 'connection',
          text: 'Podpięcie'
        },
        {
          id: 'mob',
          text: '50/50'
        },
        {
          id: 'rent',
          text: 'Wynajmujący'
        }        
      ], 
        // deals
        dealHeaders:[
          {
            text: 'Data Dodania',
            align: 'start',
            value: 'created_at',
          },
          {
            text: 'Opis',
            align: 'start',
            value: 'description',
          },
          { text: "", value: "actions", sortable: false },    
          { text: "", value: "delActions", sortable: false },           
        ],
        deals:[],
        dealOptions:{},
        totalRecordsDeal:0,
        // sms
    smsHeaders: [{
      text: 'Data Wysłania',
      align: 'start',
      value: 'created_at',
    },
      {
        text: 'Numer Telefonu',
        align: 'start',
        value: 'phone',
      },   
    {
      text: 'Treść',
      align: 'start',
      value: 'description',
    }], 
    settlementDetailsTable:[],   
    smsHistory:[],
    smsOptions:{},
    smsDescription:'',
    totalRecordsSms:0,
    smsLoading :false,
    smsDialog:false,  
      //car
    carHeaders: [
      {
        text: 'Auto',
        align: 'start',
        value: 'car',
        sortable:false,
      },    
    {
      text: 'Data Rozpoczęcia',
      align: 'start',
      value: 'startDate',
    },
    {
      text: 'Data Zakończenia',
      align: 'start',
      value: 'endDate',
    },    
  ],
  carHistory:[],
  carOptions:{},
  totalRecordCar:0,
  carLoading:false,     
        // invoice    
    invoiceHeaders: [
      { text: 'Data Wygenerowania Wypłaty', value: 'created_at' },
      {
        text: 'Tydzień',
        align: 'start',
        value: 'weekNo',
      },
      {
        text: 'Rok',
        align: 'start',
        value: 'yearNo',
      },        
      {
        text: 'Suma Do Przelewu',
        align: 'start',
        value: 'total',
        sortable: false,
      },          
      { text: '', value: 'actions', sortable: false, },
    ],
    invoices: [],
    invoiceOptions: {},
    totalRecordsInvoice: 0,     
    // invoice    
    dkvHeaders: [
      {
        text: 'Karta',
        align: 'start',
        value: 'card',
      },        
    {
      text: 'Data Dodania',
      align: 'start',
      value: 'created_at',
    },
    {
      text: 'Rodzaj',
      align: 'start',
      value: 'type',
    },
    {
      text: 'Kwota',
      align: 'start',
      value: 'price',
    },  
    { text: "", value: "actions", sortable: false }],
    dkv: [],
    dkvLoading:false,
    dkvOptions: {},
    totalRecordsDkv: 0,   
    invoiceLoading:false,
    bonusLoading: false,
    contractLoading: false,  
    // settlement
    boltEarn: 0,
    uberTaxiEarn: 0,
    uberEatsEarn: 0,
    freeNowEarn: 0,
    dkvCost: 0,
    settlementCost: 0,
    zusCost: 0,
    wireTotal: 0,
    administrationCost:0,
    dashboardDialog:false,    
        // bonus 
    bonusWeek:'',
    bonusHeaders: [{
        text: 'Data Dodania',
        align: 'start',
        value: 'created_at',
      },
      {
        text: 'Tydzień',
        align: 'start',
        value: 'weekNo',
      }, 
      {
        text: 'Rok',
        align: 'start',
        value: 'yearNo',
      },            
      {
        text: 'Opis',
        align: 'start',
        value: 'description',
      },
      {
        text: 'Rodzaj Bonusu',
        align: 'start',
        value: 'type',
      },
      {
        text: 'Kwota',
        align: 'start',
        value: 'price',
      },
      { text: "", value: "actions", sortable: false }],
        bonuses:[],
        bonusOptions:{},
        totalRecordsBonus:0,
        priceBonus:0,
        descriptionBonus:'',
        balanceType:null,
        balanceTypes: [{
          id: 'positive',
          text: 'Bonus'
        },
          {
            id: 'negative',
            text: 'Obciążenie'
          }        
        ],
        dialogBonuses:false,
        dialogContracts:false,
        descriptionContract:'',
        contractFile:'',
        // 
        dialog:false,
        dialogCreate:false,
        loading:false,
        options:{},
        totalRecords:0,
        // tbl
        drivers:[],
        num:0,
    headers: [
      {
        text: 'Imię',
        align: 'start',
        value: 'firstName',
      },
      {
        text: 'Nazwisko',
        align: 'start',
        value: 'lastName',
      },
      {
        text: 'Email',
        align: 'start',
        value: 'email',
      },
      {
        text: 'Telefon',
        align: 'start',
        value: 'phone',
      },  
      {
        text: 'Rejestracja',
        align: 'start',
        value: 'carPlate',
      },                
      { text: "", value: "actions", sortable: false },      
      { text: "", value: "actionsDel", sortable: false }, 
    ],  
      // invoices
      invoiceWeekStr:'',
    totalRecordsInvoiceCost:0,
    costinvoices:[],
    costinvoiceOptions:{},
    costinvoiceHeaders:[
      {
        text: 'Data Dodania',
        align: 'start',
        value: 'created_at',
      },
      {
        text: 'Opis',
        align: 'start',
        value: 'description',
      },       
      {
        text: 'Suma na fakturze',
        align: 'start',
        value: 'totalPrice',
      }, 
      {
        text: '% Zwrotu',
        align: 'start',
        value: 'taxPrice',
      },
      {
        text: 'Do Zwrotu',
        align: 'start',
        value: 'returnPrice',
      },                        
      { text: "", value: "actions", sortable: false },    
    ],
    invoiceCostPrice:0,
    invoiceCostDescription:'',
    taxType:null,
    taxTypes: [{
      id: 'tax_vat_fuel_range_1',
      text: 'I Stopień - Paliwo'
    },
      {
        id: 'tax_vat_fuel_range_2',
        text: 'II Stopień - Paliwo'
      },
      {
        id: 'tax_vat_fuel_range_3',
        text: 'III Stopień - Paliwo'
      }  ,
      {
        id: 'tax_vat_full_range_1',
        text: 'I Stopień - Inne'
      },
      {
        id: 'tax_vat_full_range_2',
        text: 'II Stopień - Inne'
      },
      {
        id: 'tax_vat_full_range_3',
        text: 'III Stopień - Inne'
      }                              
    ],
    invoiceCostFile:'',
    invoiceLoadingCost:false,
    dialogInvoiceCost:false,
    pitDialog:false,
    pitDriverFirstName:'',
    pitDriverLastName:'',
    pitDriverEmail:'',
    pitCheckbox1:false,
    pitCheckbox2: false ,
    // 
    dealDialog: false,
    dealDate:'',
    dealCity:'',
    dealFirstName:'',
    dealLastName: '',
    dealDriverCity:'',
    dealDriverStreet: '',
    dealDriverPhone: '',
    dealDriverDoc: '',
    dealDriverAccountNo: '',
    dealDriverCarMake: '',
    dealDriverCarModel: '',
    dealDriverCarPlate: '',
    // 
    dealDeligoDialog: false,
    deligoDate:'',
    deligoCity: '',
    deligoDriverFirstName: '',
    deligoDriverLastName:'',
    deligoDriverCity:'',
    deligoDriverStreet:'',
    deligoDriverPhone:'',
    deligoDriverId: '',
    deligoDriverAccNo:'',
    deligoDriverCarMake:'',
    deligoDriverCarModel:'',
    deligoDriverCarPlate: '',
    // 
    zusDialog:false,
    zusLastName:'',
    zusFirstName:'',
    zusSecondName: '',
    zusParentsName: '',
    zusDriverId: '',
    zusDocId:'',
    zusBirthDate: '',
    zusBirthCity: '',
    zusOryginalLastName:'',
    zusTaxOffice: '',
    zusPostalCode: '',
    zusDriverCitizenship: '',
    zusDriverCity: '',
    zusDriverDistrict:'',
    zusDriverStreet: '',
    zusDriverStreet1: '',
    zusDriverStreet2: '',
    zusPostalOtherCode: '',
    zusDriverOtherCity: '',
    zusDriverOtherDistrict: '',
    zusDriverOtherStreet: '',
    zusDriverOtherStreet1: '',
    zusDriverOtherStreet2: '',
    zusCheckbox1:false,
    zusCheckbox2: false,
    zusCheckbox3: false,
    zusCheckbox5: false,
    zusCheckbox4: false,
    zusCheckbox6: false,
    zusCheckbox7: false,
    zusCheckbox8: false,
    zusCheckbox9: false,
    zusCheckbox10: false,
    zusCheckbox11: false, 
    //
    rentDialog:false, 
    rentDate:'',
    rentDriverFirstName:'',
    rentDriverLastName: '',
    rentDriverDoc: '',
    rentDriverId: '',
    rentDriverCity: '',
    rentDriverStreet: '',
    rentDriverCarMake: '',
    rentDriverCarModel: '',
    rentDriverCarYear: '',
    rentDriverCarVin: '',
    rentDriverCarPlate: '',
    rentDriverAccNo: '',   
    // 
    statementDialog:false,
    statementDriverFirstName: '',
    statementDriverLastName: '',
    statementDriverSecondName: '',
    statementDriverBirth: '',
    statementDriverDocId: '', 
    statementDriverDocIdOther: '',
    statementDriverDistrict: '',
    statementDriverState: '', 
    statementDriverStreet: '', 
    statementDriverHome: '',
    statementDriverHome2: '',
    statementDriverPostalCode: '',
    statementDriverCity: '',
    statementDriverCountry: '',
    statementDriverCitizenship: '',
    statementOtherDriverDistrict: '',
    statementDriverOryginalLastName:'',
    statementOtherDriverState: '',
    statementOtherDriverStreet: '',
    statementOtherDriverHome: '',
    statementOtherDriverHome2: '',
    statementOtherDriverPostalCode: '',
    statementOtherDriverCity: '',
    statementOtherDriverCountry: '',
    statementOtherDriverCitizenship: '',
    statementDate1: '',
    statementDate2: '',
    statementZusDate1: '',
    statementZusDate2: '',
    university: '',
    work: '',
    statementCheckbox1: false, 
    statementCheckbox2: false, 
    statementCheckbox3: false, 
    statementCheckbox4: false,
    statementCheckbox5: false, 
    statementCheckbox6: false,
    statementCheckbox7: false, 
    statementCheckbox8: false,
    statementCheckbox9: false,
    statementCheckbox10: false, 
    statementCheckbox12: false, 
    statementCheckbox11: false,
    statementCheckbox13: false, 
    statementCheckbox14: false,
    statementCheckbox15: false,
    statementCheckbox16: false, 
    statementCheckbox17: false,
    statementCheckbox18: false,
    statementCheckbox19: false,
    statementCheckbox20: false, 
  }),
  watch: {
    options: {
      async handler() {
        await this.loadDrivers()
      },
      deep: true,
    },
    dkvOptions: {
      async handler() {
        await this.getDkvHistory()
      },
      deep: true,
    },  
    bonusOptions: {
      async handler() {
        await this.getBonusHistory()
      },
      deep: true,
    }, 
    dealOptions: {
      async handler() {
        await this.getContractsHistory()
      },
      deep: true,
    },
    costinvoiceOptions: {
      async handler() {
        await this.getInvoiceCostHistory()
      },
      deep: true,
    }, 
    invoiceOptions:{
      async handler() {
        await this.getSettlemens()
      },
      deep: true,
    },
    carOptions: {
      async handler() {
        await this.getCarsHistory()
      },
      deep: true,
    },     
    smsOptions: {
      async handler() {
        await this.getSmsHistory()
      },
      deep: true,
    },                        
    async search() {
      this.options.page = 1 
      await this.loadDrivers()
    }    
  },  
  created(){
    if(!this.showFull){
      this.currentDriverId = this.selectedDriver
    }
  }, 
  methods: {
    async previousStep(num){
      num = parseInt(num);    
        if(num>1){
            this.currentStep = num-1
        }else{
            this.currentStep = 1
        } 
    }, 
    async saveStep(num){
      num = parseInt(num);      
        if(this.currentStep<7){
            this.currentStep = num+1
        }else{
            this.currentStep =7
        }        
    },       
    async updateDocument(type){
      let data = {}
      switch(type){
        case "pit":
          data={
            driver:this.currentDriverId,
            pitDriverFirstName: this.pitDriverFirstName,
            pitDriverEmail: this.pitDriverEmail,
            pitDriverLastName: this.pitDriverLastName,
            pitCheckbox1: this.pitCheckbox1,
            pitCheckbox2: this.pitCheckbox2,
          }
          break;
        case "deal":
          data = {
            driver: this.currentDriverId,
            dealDate : this.dealDate,
            dealCity : this.dealCity,
            dealFirstName : this.dealFirstName,
            dealLastName : this.dealLastName,
            dealDriverCity : this.dealDriverCity,
            dealDriverStreet : this.dealDriverStreet,
            dealDriverPhone : this.dealDriverPhone,
            dealDriverDoc : this.dealDriverDoc,
            dealDriverAccountNo : this.dealDriverAccountNo,
            dealDriverCarMake : this.dealDriverCarMake,
            dealDriverCarModel : this.dealDriverCarModel,
            dealDriverCarPlate : this.dealDriverCarPlate,
          }
          break; 
        case "deligo":
          data = {
            driver: this.currentDriverId,
            deligoDate: this.deligoDate,
            deligoCity: this.deligoCity,
            deligoDriverFirstName: this.deligoDriverFirstName,
            deligoDriverLastName: this.deligoDriverLastName,
            deligoDriverCity: this.deligoDriverCity,
            deligoDriverStreet: this.deligoDriverStreet,
            deligoDriverPhone: this.deligoDriverPhone,
            deligoDriverId: this.deligoDriverId,
            deligoDriverAccNo: this.deligoDriverAccNo,
            deligoDriverCarMake: this.deligoDriverCarMake,
            deligoDriverCarModel: this.deligoDriverCarModel,
            deligoDriverCarPlate: this.deligoDriverCarPlate,
          }
          break;                    
        case "zus":
          data = {
            driver: this.currentDriverId,
            zusLastName : this.zusLastName,
            zusFirstName : this.zusFirstName,
            zusSecondName : this.zusSecondName,
            zusParentsName : this.zusParentsName,
            zusDriverId : this.zusDriverId,
            zusDocId : this.zusDocId,
            zusBirthDate : this.zusBirthDate,
            zusBirthCity : this.zusBirthCity,
            zusOryginalLastName : this.zusOryginalLastName,
            zusTaxOffice : this.zusTaxOffice,
            zusPostalCode : this.zusPostalCode,
            zusDriverCitizenship : this.zusDriverCitizenship,
            zusDriverCity : this.zusDriverCity,
            zusDriverDistrict : this.zusDriverDistrict,
            zusDriverStreet : this.zusDriverStreet,
            zusDriverStreet1 : this.zusDriverStreet1,
            zusDriverStreet2 : this.zusDriverStreet2,
            zusPostalOtherCode : this.zusPostalOtherCode,
            zusDriverOtherCity : this.zusDriverOtherCity,
            zusDriverOtherDistrict : this.zusDriverOtherDistrict,
            zusDriverOtherStreet : this.zusDriverOtherStreet,
            zusDriverOtherStreet1 : this.zusDriverOtherStreet1,
            zusDriverOtherStreet2 : this.zusDriverOtherStreet2,
            zusCheckbox1 : this.zusCheckbox1,
            zusCheckbox2 : this.zusCheckbox2,
            zusCheckbox3 : this.zusCheckbox3,
            zusCheckbox5 : this.zusCheckbox4,
            zusCheckbox4 : this.zusCheckbox5,
            zusCheckbox6 : this.zusCheckbox6,
            zusCheckbox7 : this.zusCheckbox7,
            zusCheckbox8 : this.zusCheckbox8,
            zusCheckbox9 : this.zusCheckbox9,
            zusCheckbox10 : this.zusCheckbox10,
            zusCheckbox11 : this.zusCheckbox11,
          }   
          break;
          case 'rent': 
            data = {
              driver: this.currentDriverId,
              rentDate: this.rentDate,
              rentDriverFirstName: this.rentDriverFirstName,
              rentDriverLastName: this.rentDriverLastName,
              rentDriverDoc: this.rentDriverDoc,
              rentDriverId: this.rentDriverId,
              rentDriverCity: this.rentDriverCity,
              rentDriverStreet: this.rentDriverStreet,
              rentDriverCarMake: this.rentDriverCarMake,
              rentDriverCarModel: this.rentDriverCarModel,
              rentDriverCarYear: this.rentDriverCarYear,
              rentDriverCarVin: this.rentDriverCarVin,
              rentDriverCarPlate: this.rentDriverCarPlate,
              rentDriverAccNo: this.rentDriverAccNo, 
            }           
          break; 
        case 'statement':
          data = {
            driver: this.currentDriverId,
            statementDriverFirstName : this.statementDriverFirstName,
            statementDriverOryginalLastName: this.statementDriverOryginalLastName,
            statementDriverLastName : this.statementDriverLastName,
            statementDriverSecondName : this.statementDriverSecondName,
            statementDriverBirth : this.statementDriverBirth,
            statementDriverDocId : this.statementDriverDocId,
            statementDriverDocIdOther : this.statementDriverDocIdOther,
            statementDriverDistrict : this.statementDriverDistrict,
            statementDriverState : this.statementDriverState,
            statementDriverStreet : this.statementDriverStreet,
            statementDriverHome : this.statementDriverHome,
            statementDriverHome2 : this.statementDriverHome2,
            statementDriverPostalCode : this.statementDriverPostalCode,
            statementDriverCity : this.statementDriverCity,
            statementDriverCountry : this.statementDriverCountry,
            statementDriverCitizenship : this.statementDriverCitizenship,
            statementOtherDriverDistrict : this.statementOtherDriverDistrict,
            statementOtherDriverState : this.statementOtherDriverState,
            statementOtherDriverStreet : this.statementOtherDriverStreet,
            statementOtherDriverHome : this.statementOtherDriverHome,
            statementOtherDriverHome2 : this.statementOtherDriverHome2,
            statementOtherDriverPostalCode : this.statementOtherDriverPostalCode,
            statementOtherDriverCity : this.statementOtherDriverCity,
            statementOtherDriverCountry : this.statementOtherDriverCountry,
            statementOtherDriverCitizenship : this.statementOtherDriverCitizenship,
            statementDate1 : this.statementDate1,
            statementDate2 : this.statementDate2,
            statementZusDate1 : this.statementZusDate1,
            statementZusDate2 : this.statementZusDate2,
            university : this.university,
            work : this.work,
            statementCheckbox1 : this.statementCheckbox1,
            statementCheckbox2 : this.statementCheckbox2,
            statementCheckbox3 : this.statementCheckbox3,
            statementCheckbox4 : this.statementCheckbox4,
            statementCheckbox5 : this.statementCheckbox5,
            statementCheckbox6 : this.statementCheckbox6,
            statementCheckbox7 : this.statementCheckbox7,
            statementCheckbox8 : this.statementCheckbox8,
            statementCheckbox9 : this.statementCheckbox9,
            statementCheckbox10 : this.statementCheckbox10,
            statementCheckbox12 : this.statementCheckbox11,
            statementCheckbox11 : this.statementCheckbox12,
            statementCheckbox13 : this.statementCheckbox13,
            statementCheckbox14 : this.statementCheckbox14,
            statementCheckbox15 : this.statementCheckbox15,
            statementCheckbox16 : this.statementCheckbox16,
            statementCheckbox17 : this.statementCheckbox17,
            statementCheckbox18 : this.statementCheckbox18,
            statementCheckbox19 : this.statementCheckbox19,
            statementCheckbox20 : this.statementCheckbox20,
          }
          break;                 
      }
      await this.toggleLoading(true)
      try{
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + `/documents/update-document/${type}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data
        });
        Swal.fire("Sukces","Zapisano",'success')
      }catch(e){
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false)
    },
    async downloadDocument(type){
      try{
        const file = await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + `/documents/download-document/${this.currentDriverId}/${type}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          responseType: 'blob'
        });
        let blob = new Blob([file.data], { type: file.data.type }),
        url = window.URL.createObjectURL(blob)
        window.open(url)          
      }catch(e){
        console.log(e)
        Swal.fire("Błąd", "Nie Można Pobrać Pliku", "warning")
      }      
    },
    async showPitForm(){
      this.pitDialog = true
      const details = await axios({
        method: "get",
        url: process.env.VUE_APP_ROOT_API + `/documents/get-document-for-driver/${this.currentDriverId}/pit`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }); 
      this.pitDriverFirstName = details.data.data.pitDriverFirstName
      this.pitDriverEmail = details.data.data.pitDriverEmail
      this.pitDriverLastName = details.data.data.pitDriverLastName
      this.pitCheckbox1 = details.data.data.pitCheckbox1
      this.pitCheckbox2 = details.data.data.pitCheckbox2
    },
    async showZusForm() {
      this.zusDialog = true
      const details = await axios({
        method: "get",
        url: process.env.VUE_APP_ROOT_API + `/documents/get-document-for-driver/${this.currentDriverId}/zus`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      this.zusLastName = details.data.data.zusLastName
      this.zusFirstName = details.data.data.zusFirstName
      this.zusSecondName = details.data.data.zusSecondName
      this.zusParentsName = details.data.data.zusParentsName
      this.zusDriverId = details.data.data.zusDriverId
      this.zusDocId = details.data.data.zusDocId
      this.zusBirthDate = details.data.data.zusBirthDate
      this.zusBirthCity = details.data.data.zusBirthCity
      this.zusOryginalLastName = details.data.data.zusOryginalLastName
      this.zusTaxOffice = details.data.data.zusTaxOffice
      this.zusPostalCode = details.data.data.zusPostalCode
      this.zusDriverCitizenship = details.data.data.zusDriverCitizenship
      this.zusDriverCity = details.data.data.zusDriverCity
      this.zusDriverDistrict = details.data.data.zusDriverDistrict
      this.zusDriverStreet = details.data.data.zusDriverStreet
      this.zusDriverStreet1 = details.data.data.zusDriverStreet1
      this.zusDriverStreet2 = details.data.data.zusDriverStreet2
      this.zusPostalOtherCode = details.data.data.zusPostalOtherCode
      this.zusDriverOtherCity = details.data.data.zusDriverOtherCity
      this.zusDriverOtherDistrict = details.data.data.zusDriverOtherDistrict
      this.zusDriverOtherStreet = details.data.data.zusDriverOtherStreet
      this.zusDriverOtherStreet1 = details.data.data.zusDriverOtherStreet1
      this.zusDriverOtherStreet2 = details.data.data.zusDriverOtherStreet2
      this.zusCheckbox1 = details.data.data.zusCheckbox1
      this.zusCheckbox2 = details.data.data.zusCheckbox2
      this.zusCheckbox3 = details.data.data.zusCheckbox3
      this.zusCheckbox5 = details.data.data.zusCheckbox4
      this.zusCheckbox4 = details.data.data.zusCheckbox5
      this.zusCheckbox6 = details.data.data.zusCheckbox6
      this.zusCheckbox7 = details.data.data.zusCheckbox7
      this.zusCheckbox8 = details.data.data.zusCheckbox8
      this.zusCheckbox9 = details.data.data.zusCheckbox9
      this.zusCheckbox10 = details.data.data.zusCheckbox10
      this.zusCheckbox11 = details.data.data.zusCheckbox11
    }, 
    async showRentForm() {
      this.rentDialog = true
      const details = await axios({
        method: "get",
        url: process.env.VUE_APP_ROOT_API + `/documents/get-document-for-driver/${this.currentDriverId}/rent`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      this.rentDate = details.data.data.rentDate
      this.rentDriverFirstName = details.data.data.rentDriverFirstName
      this.rentDriverLastName = details.data.data.rentDriverLastName
      this.rentDriverDoc = details.data.data.rentDriverDoc
      this.rentDriverId = details.data.data.rentDriverId
      this.rentDriverCity = details.data.data.rentDriverCity
      this.rentDriverStreet = details.data.data.rentDriverStreet
      this.rentDriverCarMake = details.data.data.rentDriverCarMake
      this.rentDriverCarModel = details.data.data.rentDriverCarModel
      this.rentDriverCarYear = details.data.data.rentDriverCarYear
      this.rentDriverCarVin = details.data.data.rentDriverCarVin
      this.rentDriverCarPlate = details.data.data.rentDriverCarPlate
      this.rentDriverAccNo = details.data.data.rentDriverAccNo
    }, 
    async showStatementForm() {
      this.statementDialog = true
      const details = await axios({
        method: "get",
        url: process.env.VUE_APP_ROOT_API + `/documents/get-document-for-driver/${this.currentDriverId}/statement`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      this.statementDriverFirstName = details.data.data.statementDriverFirstName
      this.statementDriverLastName = details.data.data.statementDriverLastName
      this.statementDriverOryginalLastName = details.data.data.statementDriverOryginalLastName
      this.statementDriverSecondName = details.data.data.statementDriverSecondName
      this.statementDriverBirth = details.data.data.statementDriverBirth
      this.statementDriverDocId = details.data.data.statementDriverDocId
      this.statementDriverDocIdOther = details.data.data.statementDriverDocIdOther
      this.statementDriverDistrict = details.data.data.statementDriverDistrict
      this.statementDriverState = details.data.data.statementDriverState
      this.statementDriverStreet = details.data.data.statementDriverStreet
      this.statementDriverHome = details.data.data.statementDriverHome
      this.statementDriverHome2 = details.data.data.statementDriverHome2
      this.statementDriverPostalCode = details.data.data.statementDriverPostalCode
      this.statementDriverCity = details.data.data.statementDriverCity
      this.statementDriverCountry = details.data.data.statementDriverCountry
      this.statementDriverCitizenship = details.data.data.statementDriverCitizenship
      this.statementOtherDriverDistrict = details.data.data.statementOtherDriverDistrict
      this.statementOtherDriverState = details.data.data.statementOtherDriverState
      this.statementOtherDriverStreet = details.data.data.statementOtherDriverStreet
      this.statementOtherDriverHome = details.data.data.statementOtherDriverHome
      this.statementOtherDriverHome2 = details.data.data.statementOtherDriverHome2
      this.statementOtherDriverPostalCode = details.data.data.statementOtherDriverPostalCode
      this.statementOtherDriverCity = details.data.data.statementOtherDriverCity
      this.statementOtherDriverCountry = details.data.data.statementOtherDriverCountry
      this.statementOtherDriverCitizenship = details.data.data.statementOtherDriverCitizenship
      this.statementDate1 = details.data.data.statementDate1
      this.statementDate2 = details.data.data.statementDate2
      this.statementZusDate1 = details.data.data.statementZusDate1
      this.statementZusDate2 = details.data.data.statementZusDate2
      this.university = details.data.data.university
      this.work = details.data.data.work
      this.statementCheckbox1 = details.data.data.statementCheckbox1
      this.statementCheckbox2 = details.data.data.statementCheckbox2
      this.statementCheckbox3 = details.data.data.statementCheckbox3
      this.statementCheckbox4 = details.data.data.statementCheckbox4
      this.statementCheckbox5= details.data.data.statementCheckbox5
      this.statementCheckbox6 = details.data.data.statementCheckbox6
      this.statementCheckbox7= details.data.data.statementCheckbox7
      this.statementCheckbox8 = details.data.data.statementCheckbox8
      this.statementCheckbox9 = details.data.data.statementCheckbox9
      this.statementCheckbox10 = details.data.data.statementCheckbox10
      this.statementCheckbox12 = details.data.data.statementCheckbox11
      this.statementCheckbox11 = details.data.data.statementCheckbox12
      this.statementCheckbox13 = details.data.data.statementCheckbox13
      this.statementCheckbox14 = details.data.data.statementCheckbox14
      this.statementCheckbox15 = details.data.data.statementCheckbox15
      this.statementCheckbox16 = details.data.data.statementCheckbox16
      this.statementCheckbox17 = details.data.data.statementCheckbox17
      this.statementCheckbox18 = details.data.data.statementCheckbox18
      this.statementCheckbox19 = details.data.data.statementCheckbox19
      this.statementCheckbox20 = details.data.data.statementCheckbox20
    }, 
    async showDealForm() {
      this.dealDialog = true
      const details = await axios({
        method: "get",
        url: process.env.VUE_APP_ROOT_API + `/documents/get-document-for-driver/${this.currentDriverId}/deal`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      this.dealDate = details.data.data.dealDate
      this.dealCity = details.data.data.dealCity
      this.dealFirstName = details.data.data.dealFirstName
      this.dealLastName = details.data.data.dealLastName
      this.dealDriverCity = details.data.data.dealDriverCity
      this.dealDriverStreet = details.data.data.dealDriverStreet
      this.dealDriverPhone = details.data.data.dealDriverPhone
      this.dealDriverDoc = details.data.data.dealDriverDoc
      this.dealDriverAccountNo = details.data.data.dealDriverAccountNo
      this.dealDriverCarMake = details.data.data.dealDriverCarMake
      this.dealDriverCarModel = details.data.data.dealDriverCarModel
      this.dealDriverCarPlate = details.data.data.dealDriverCarPlate
    }, 
    async showDeligoForm() {
      this.dealDeligoDialog = true
      const details = await axios({
        method: "get",
        url: process.env.VUE_APP_ROOT_API + `/documents/get-document-for-driver/${this.currentDriverId}/deligo`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      this.deligoDate = details.data.data.deligoDate
      this.deligoCity = details.data.data.deligoCity
      this.deligoDriverFirstName = details.data.data.deligoDriverFirstName
      this.deligoDriverLastName = details.data.data.deligoDriverLastName
      this.deligoDriverCity = details.data.data.deligoDriverCity
      this.deligoDriverStreet = details.data.data.deligoDriverStreet
      this.deligoDriverPhone = details.data.data.deligoDriverPhone
      this.deligoDriverId = details.data.data.deligoDriverId
      this.deligoDriverAccNo = details.data.data.deligoDriverAccNo
      this.deligoDriverCarMake = details.data.data.deligoDriverCarMake
      this.deligoDriverCarModel = details.data.data.deligoDriverCarModel
      this.deligoDriverCarPlate = details.data.data.deligoDriverCarPlate
    },                    
    async showDialog(num) {  
      if(!num){
        this.action = 'Dodawanie'
        this.dialogCreate = true
        this.currentDriverId = null
        this.isReposession = false
        this.reposessionAccountNo = ''
        this.reposessionWireTitle = ''        
        this.firstName = ''
        this.lastName = ''
        this.email = ''
        this.phone = ''
        this.departament = null
        this.boltId = ''
        this.uberId = ''
        this.isEmployed = false
        this.isUnder26 = false
        this.isStudent = false
        this.uberEatsId =''
        this.freeId = ''
        this.deligoId = ''
        this.street = ''
        this.streetExtraInfo = ''
        this.city = ''
        this.licenceNumber = ''
        this.abroadBankAccount = false
        this.cashPayments = false
        this.accountNo = '';
        this.password = null;
        this.carMake = '';
        this.carPlate = '';  
        this.reposessionAddressLine1= ''
        this.reposessionAddressLine2 = ''
        this.reposessionAddressLine3 = ''
        this.reposessionAddressLine4 = ''  
        this.paymentSchedule=null
        this.cooperationType=null
        this.zusType = null        
        await this.getDepartamentSelect() 
      }else if(num=='bonuses'){
        this.dialogBonuses = true
      } else if (num == 'contracts') {
        this.dialogContracts = true
      }else if (num == 'invoiceCost') {
        this.dialogInvoiceCost = true
      }  
      else if (num == 'sms') {
        this.smsDialog = true
      }          
   
    },
    async deleteInvoice(id) {
      await this.toggleLoading(true);
      try {
        Swal.fire({
          title: "Usunąć Fakturę?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + `/invoices/delete-document/${id}`,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.getInvoiceCostHistory()
          }
        })
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);      
    },    
    async createBonus(){
      this.bonusLoading = true
      try{
        if (!this.bonusWeek.trim().length) {
          throw ({ response: { statusText: 'Brak Wybranego Tygodnia' } })
        }          
        if (!this.descriptionBonus.trim().length) {
          throw ({ response: { statusText: 'Brak Opisu Rekodu' } })
        }  
        if (!this.balanceType.trim().length) {
          throw ({ response: { statusText: 'Brak Rodzaju Rekodu' } })
        }  
        if (!this.priceBonus.trim().length) {
          throw ({ response: { statusText: 'Brak Kwoty' } })
        }  
        if (!this.descriptionBonus.trim().length) {
          throw ({ response: { statusText: 'Brak Opisu Rekodu' } })
        }                                   
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "/bonuses",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            price: this.priceBonus,
            description: this.descriptionBonus,
            type: this.balanceType,
            driver: this.currentDriverId,
            weekNo:this.bonusWeek
          },
        });
        this.bonusWeek = ''
        this.priceBonus = '';
        this.descriptionBonus = ''
        this.dialogBonuses = false
        this.balanceType = null    
        await this.getBonusHistory()      
      }catch(e){
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.bonusLoading = false
    },
    async getDashboard(id){
      await this.toggleLoading(true)
      this.dashboardDialog = true
      const details = await axios({
        method: "get",
        url: process.env.VUE_APP_ROOT_API + `/settlements/get-dashboard-for-id-admin/${id}/${this.currentDriverId}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      this.settlementDetailsTable= []
      this.settlementDetailsTable.push({
        'name': 'Rozliczenie za:',
        'price': (details.data.data.weekNo+'/'+ details.data.data.yearNo)
      })      
      this.settlementDetailsTable.push({
        'name':'Łącznie Bolt',
        'price': details.data.data.boltSettlement||'0,00'
      })
      this.settlementDetailsTable.push({
        'name': 'Łącznie Uber',
        'price': details.data.data.uberSettlements || '0,00'
      })  
      this.settlementDetailsTable.push({
        'name': 'Łącznie FreeNow',
        'price': details.data.data.freeNowSettlements || '0,00'
      }) 
      this.settlementDetailsTable.push({
        'name': 'Łącznie UberEats',
        'price': details.data.data.uberEatsSettlements || '0,00'
      })        
      this.settlementDetailsTable.push({
        'name': 'Łącznie Deligo',
        'price': details.data.data.deligoSettlement || '0,00'
      })
      this.settlementDetailsTable.push({
        'name': 'Łącznie Bonusy',
        'price': details.data.data.bonusSettlements || '0,00'
      })      
      this.settlementDetailsTable.push({
        'name': 'Łącznie DKV',
        'price': details.data.data.dkvSettlements || '0,00'
      }) 
      this.settlementDetailsTable.push({
        'name': 'Wynajem Auta',
        'price': parseFloat(details.data.data.rentPrice).toFixed(2).replace('.', ',') || '0,00'
      })            
      this.settlementDetailsTable.push({
        'name': 'Łącznie Zwrot (faktury)',
        'price': details.data.data.invoicesSettlements || '0,00'
      })      
      this.settlementDetailsTable.push({
        'name': 'ZUS',
        'price': (details.data.data.zusSettlements/100).toFixed(2).replace('.',',')
      }) 
      this.settlementDetailsTable.push({
        'name': 'Koszt Rozliczenia',
        'price': (details.data.data.settlementCost/100).toFixed(2).replace('.', ',')
      })
      this.settlementDetailsTable.push({
        'name': 'Koszt Administracyjno-Księgowy',
        'price': (details.data.data.administrationTax/100).toFixed(2).replace('.', ',')
      }) 
      this.settlementDetailsTable.push({
        'name': 'Łącznie Gotówka',
        'price': (details.data.data.totalCash / 100).toFixed(2).replace('.', ',') || '0,00'
      })      
      this.settlementDetailsTable.push({
        'name': 'Przelew Zlecenie',
        'price': (details.data.data.totalZus/100).toFixed(2).replace('.', ',')
      })
      this.settlementDetailsTable.push({
        'name': 'Przelew Wynajem',
        'price': (details.data.data.totalRent/100).toFixed(2).replace('.', ',')
      }) 
      this.settlementDetailsTable.push({
        'name': 'Łącznie Przelew',
        'price': ((details.data.data.totalTotal+ details.data.data.totalZus+ details.data.data.totalRent) /100).toFixed(2).replace('.', ',')
      })                                                                
      await this.toggleLoading(false)  
    },
    async sendSms() {
      this.smsLoading = true
      try {
        if (!this.smsDescription.trim().length) {
          throw ({ response: { statusText: 'Brak Treści SMS' } })
        }         
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "/drivers/send-sms",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            smsText: this.smsDescription,
            driver: this.currentDriverId
          },
        });
        this.smsDescription = ''
        this.smsDialog = false        
        await this.getSmsHistory()
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.smsLoading = false
    },    
    async showDeal(id){
      try {
        const file = await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + `/contracts/get-document/${id}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          responseType: "blob"          
        });  
        let blob = new Blob([file.data], { type: file.data.type }),
          url = window.URL.createObjectURL(blob)

        window.open(url)              
      }catch(e){
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
    },
    async showInvoiceCost(id) {
      try {
        const file = await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + `/invoices/show-document/${id}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          responseType: "blob"
        });
        let blob = new Blob([file.data], { type: file.data.type }),
          url = window.URL.createObjectURL(blob)

        window.open(url)
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
    },    
    async createContract() {
      this.contractLoading = true
      try {
        const formData = new FormData()
        try{
          formData.append("file", this.contractFile, this.contractFile.name);
        }catch(e){
          console.log('Missing File')
        }
        
        formData.append('driver', this.currentDriverId)
        if (!this.descriptionContract.trim().length) {
          throw ({ response: { statusText: 'Brak Opisu Dokumentu!' } })
        } 
        formData.append('description', this.descriptionContract)  
        formData.append('contractType', this.contractType)        
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "/contracts/upload-document",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: formData,
        });        
        this.descriptionContract = ''
        this.contractType='other'
        this.dialogContracts = false        
        await this.getContractsHistory()
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.contractLoading = false
    },  
    async createCostInvoice(){
      this.invoiceLoadingCost = true
      try {
        const formData = new FormData()
        try{
          formData.append("file", this.invoiceCostFile, this.invoiceCostFile.name);
        }catch(e){
          console.log('missing file')
        }
        if (!this.invoiceWeekStr.trim().length) {
          throw ({ response: { statusText: 'Brak Wybranego Tygodnia!' } })
        }  
        if (!this.invoiceCostPrice.trim().length) {
          throw ({ response: { statusText: 'Brak Ceny!' } })
        } 
        if (!this.invoiceCostDescription.trim().length) {
          throw ({ response: { statusText: 'Brak Opisu Faktury!' } })
        } 
        if (!this.taxType.trim().length) {
          throw ({ response: { statusText: 'Brak Stawki Zwrotu Podatku!' } })
        }                                
        formData.append('driver', this.currentDriverId)
        formData.append('weekStr', this.invoiceWeekStr)
        formData.append('totalPrice', this.invoiceCostPrice)
        formData.append('description', this.invoiceCostDescription)
        formData.append('taxRange', this.taxType)
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "/invoices/upload-document",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: formData,
        });
        this.invoiceCostFile = null
        this.invoiceCostPrice = 0
        this.invoiceWeekStr=''
        this.invoiceCostDescription = ''
        this.taxType = null        
        this.dialogInvoiceCost = false
        await this.getInvoiceCostHistory()
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.invoiceLoadingCost = false      
    },  
    async getCarsHistory(){
      this.carLoading = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.carOptions
        const cars = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/drivers/get-car-history/${this.currentDriverId}?sort=${sortBy.length ? sortBy : 'startDate'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        cars.data.data.items.forEach((item) => {
          item.car = item.car.plate          
          item.startDate = dayjs(item.startDate).format('YYYY-MM-DD')
          item.endDate = dayjs(item.endDate).format('YYYY-MM-DD')
        })
        this.carHistory = cars.data.data.items
        this.itemsPerPage = cars.data.data.meta.itemsPerPage
        this.page = cars.data.data.meta.currentPage
        this.totalRecordCar = cars.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.carLoading = false       
    },
    async getDkvHistory(){
      this.dkvLoading = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.dkvOptions
        const dkvs = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/dkv/list-dkv-history-for-driver/${this.currentDriverId}?sort=${sortBy.length ? sortBy : 'created_at'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        dkvs.data.data.items.forEach((item) => {
          item.card = item.card.dkvNo
          item.type = item.type == 'positive' ? 'Doładowanie' : 'Wydatek'
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm') 
          item.price = (item.price / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" });         
        })
        this.dkv = dkvs.data.data.items
        this.itemsPerPage = dkvs.data.data.meta.itemsPerPage
        this.page = dkvs.data.data.meta.currentPage
        this.totalRecordsDkv = dkvs.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.dkvLoading = false 
    },
    async getSmsHistory() {
      this.smsLoading = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.smsOptions
        const sms = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/drivers/sms-history/${this.currentDriverId}?sort=${sortBy.length ? sortBy : 'created_at'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        sms.data.data.items.forEach((item) => {
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
        })
        this.smsHistory = sms.data.data.items
        this.itemsPerPage = sms.data.data.meta.itemsPerPage
        this.page = sms.data.data.meta.currentPage
        this.totalRecordsSms = sms.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.smsLoading = false
    },    
    async getBonusHistory() {
      this.dkvLoading = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.bonusOptions
        const bonus = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/bonuses/${this.currentDriverId}?sort=${sortBy.length ? sortBy : 'created_at'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        bonus.data.data.items.forEach((item) => { 
          switch(item.type){
            case 'positive':
              item.type = 'Premia'
              break;
            case 'rent':
              item.type = 'Wypożyczenie'
              break;
            case 'negative':
              item.type = 'Obciążenie'
              break;                           
          }  
          item.price = (item.price / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" });                          
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')          
        })
        this.bonuses = bonus.data.data.items
        this.itemsPerPage = bonus.data.data.meta.itemsPerPage
        this.page = bonus.data.data.meta.currentPage
        this.totalRecordsBonus = bonus.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.dkvLoading = false
    },   
    async getContractsHistory() {
      this.contractLoading = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.dealOptions
        const contracts = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/contracts/get-documents/${this.currentDriverId}?sort=${sortBy.length ? sortBy : 'created_at'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        contracts.data.data.items.forEach((item) => {          
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
        })
        this.deals = contracts.data.data.items
        this.itemsPerPage = contracts.data.data.meta.itemsPerPage
        this.page = contracts.data.data.meta.currentPage
        this.totalRecordsDeal = contracts.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.contractLoading = false
    }, 
    async getSettlemens(){
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.invoiceOptions
        const invoices = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/settlements/get-payments-for-driver/${this.currentDriverId}?sort=${sortBy.length ? sortBy : 'created_at'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        invoices.data.data.items.forEach((item) => {
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
          item.total = ((item.totalZus + item.totalRent + item.totalReturn) / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" });
        })        
        this.invoices = invoices.data.data.items
        this.itemsPerPage = invoices.data.data.meta.itemsPerPage
        this.page = invoices.data.data.meta.currentPage
        this.totalRecordsInvoice = invoices.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }      
      
      this.invoiceLoading = false
    },
    async getInvoiceCostHistory() {
      this.invoiceLoadingCost = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.costinvoiceOptions        
        const invoices = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/invoices/get-documents/${this.currentDriverId}?sort=${sortBy.length != 0 ? sortBy : 'created_at'}&direction=${sortDesc?sortDesc:'true'}&page=${page?page:1}&limit=${itemsPerPage?itemsPerPage:10}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        invoices.data.data.items.forEach((item) => {
          item.totalPrice = (item.totalPrice / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" }); 
          item.taxPrice = (item.taxPrice / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" }); 
          item.returnPrice = (item.returnPrice / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" });         
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
        })
        this.costinvoices = invoices.data.data.items
        this.itemsPerPage = invoices.data.data.meta.itemsPerPage
        this.page = invoices.data.data.meta.currentPage
        this.totalRecordsInvoiceCost = invoices.data.data.meta.totalItems
      } catch (e) {
        console.log(e)        
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.invoiceLoadingCost = false
    },            
    async showEdit(){      
      this.action = 'Edycja'
      this.dialogCreate = true
      await this.getDepartamentSelect()
      await this.toggleLoading(true);
      this.dialogCreateLoading =true
      try {
        let driver = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/drivers/" + this.currentDriverId,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });     
            this.firstName= driver.data.data.firstName,
            this.lastName = driver.data.data.lastName,
            this.isUnder26 = driver.data.data.isUnder26 
            this.isStudent = driver.data.data.isStudent 
            this.isEmployed = driver.data.data.isEmployed      
            this.email = driver.data.data.email,
            this.phone = driver.data.data.phone,
            this.departament = driver.data.data.departament.id,
            this.boltId = driver.data.data.boltId,   
            this.uberId = driver.data.data.uberId,  
            this.deligoId = driver.data.data.deligoId
            this.uberEatsId = driver.data.data.uberEatsId,  
            this.freeId = driver.data.data.freeId,            
            this.street = driver.data.data.street,            
            this.streetExtraInfo = driver.data.data.streetExtraInfo,            
            this.city = driver.data.data.city,            
            this.licenceNumber = driver.data.data.licenceNumber,            
            this.abroadBankAccount = driver.data.data.abroadBankAccount           
            this.cashPayments = driver.data.data.cashPayments       
            this.accountNo = driver.data.data.accountNo,            
            this.paymentSchedule = driver.data.data.paymentSchedule,  
            this.zusType = driver.data.data.zusType,            
            this.cooperationType = driver.data.data.cooperationType   
            this.carMake = driver.data.data.carMake  
            this.carPlate = driver.data.data.carPlate  
            this.isReposession = driver.data.data.isReposession 
            this.reposessionAccountNo = driver.data.data.reposessionAccountNo
            this.reposessionWireTitle = driver.data.data.reposessionWireTitle   
            this.reposessionAddressLine1 = driver.data.data.reposessionAddressLine1   
            this.reposessionAddressLine2 = driver.data.data.reposessionAddressLine2   
            this.reposessionAddressLine3 = driver.data.data.reposessionAddressLine3   
            this.reposessionAddressLine4 = driver.data.data.reposessionAddressLine4               
      } catch (e) {
        console.log(e);
        this.dialogCreate = false;
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.dialogCreateLoading = false
      await this.toggleLoading(false);      
    },
    async deleteBonus(id) {
      await this.toggleLoading(true);
      try {
        Swal.fire({
          title: "Usunąć Bonus?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "/bonuses/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.getBonusHistory()
            await this.loadDepartaments()
          }
        });
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);     
    }, 
    async deleteContract(id) {
      await this.toggleLoading(true);
      try {
        Swal.fire({
          title: "Usunąć Dokument?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "/contracts/delete-document/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });  
            await this.getContractsHistory()          
          }
        });
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);      
    },       
    async deleteDriver(id) {
      await this.toggleLoading(true);
      try {
        Swal.fire({
          title: "Usunąć Kierowcę?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "/drivers/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });     
            await this.loadDrivers()       
          }
        });
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);      
    },       
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },  
    async getDriverById(id){
      await this.toggleLoading(true);
      this.currentDriverId = id
      this.dialog = true
      try{
        let driver = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/drivers/" + this.currentDriverId,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });         
        this.name = driver.data.data.firstName+' '+driver.data.data.lastName
      }catch(e){
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
      if(this.num!=0){
        await this.getDkvHistory()
        await this.getSettlemens()
        await this.getBonusHistory()
        await this.getContractsHistory()
        await this.getInvoiceCostHistory()
        await this.getCarsHistory()
        await this.getSmsHistory()
      }
      this.num=1      
    },
    async getDepartamentSelect() {
      await this.toggleLoading(true);
      try {
        let dep = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/departaments/list-departaments?itemsPerPage=100&sort=departamentName&direction=false",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.departaments = dep.data.data.items;
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
    }, 
    async validateDriverForm(){
      if(!this.firstName.trim().length){
        throw({response:{
          statusText:'Uzupełnij Imię'
        }})
      }
      if (!this.currentDriverId) {
        if (!this.password.trim().length) {
          throw ({
            response: {
              statusText: 'Uzupełnij Hasło'
            }
          })
        }  
      }    
      if(!this.lastName.trim().length){
        throw({response:{
          statusText:'Uzupełnij Nazwisko'
        }})
      }
      if(!this.email.trim().length){
        throw({response:{
          statusText:'Uzupełnij E-mail'
        }})
      }
      if(!this.phone.trim().length){
        throw({response:{
          statusText:'Uzupełnij Telefon'
        }})
      }
      if(!this.departament){
        throw({response:{
          statusText:'Uzupełnij Oddział'
        }})
      }
      if(!this.street.trim().length){
        throw({response:{
          statusText:'Uzupełnij Adres'
        }})
      }
      if(!this.streetExtraInfo.trim().length){
        throw({response:{
          statusText:'Uzupełnij Adres 2'
        }})
      }
      if(!this.city.trim().length){
        throw({response:{
          statusText:'Uzupełnij Miasto'
        }})
      }
      if (!this.cashPayments) {
        if (!this.accountNo.trim().length) {
          throw ({
            response: {
              statusText: 'Uzupełnij Numer Konta'
            }
          })
        } else if (this.accountNo.trim().length !== 26) {
          throw ({
            response: {
              statusText: 'Numer Konta musi posiadać 26 znaków'
            }
          })
        }
      }
      if(!this.paymentSchedule.trim().length){
        throw({response:{
          statusText:'Uzupełnij Częstotliwość Rozliczeń'
        }})
      }
      if(!this.paymentSchedule.trim().length){
        throw({response:{
          statusText:'Uzupełnij Rodzaj Zus'
        }})
      }      
      if(!this.cooperationType.trim().length){
        throw({response:{
          statusText:'Uzupełnij Sposób Rozliczeń'
        }})
      }
    },
    async createDriver() {      
      
      let method = "";
      let url = "";
      if (this.currentDriverId) {
        method = "patch";
        url = process.env.VUE_APP_ROOT_API + "/drivers/" + this.currentDriverId;
      } else {
        method = "post";
        url = process.env.VUE_APP_ROOT_API + "/drivers";
      }
      try {
        await this.validateDriverForm()
        await this.toggleLoading(true);
        await axios({
          method: method,
          url: url,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            password:this.password ?( this.password.trim().length > 0 ? this.password : null):null,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,            
            phone: this.phone,            
            departament: this.departament,
            boltId: this.boltId ? this.boltId.trim() : '',
            uberId: this.uberId ? this.uberId.trim() : '',
            uberEatsId: this.uberEatsId ? this.uberEatsId.trim() : '',
            isUnder26:this.isUnder26,
            isStudent: this.isStudent,
            isEmployed: this.isEmployed,
            freeId: this.freeId?this.freeId.trim():'',
            deligoId: this.deligoId ? this.deligoId.trim() : '',
            street: this.street,
            streetExtraInfo: this.streetExtraInfo,
            city: this.city,
            licenceNumber: this.licenceNumber,
            abroadBankAccount: this.abroadBankAccount,
            cashPayments: this.cashPayments,
            accountNo: this.accountNo.replaceAll(' ',''),
            paymentSchedule: this.paymentSchedule,            
            cooperationType: this.cooperationType,
            carMake: this.carMake,
            carPlate: this.carPlate, 
            isReposession: this.isReposession,
            reposessionAccountNo: this.reposessionAccountNo,
            reposessionWireTitle: this.reposessionWireTitle,    
            reposessionAddressLine1:this.reposessionAddressLine1,
            reposessionAddressLine2:this.reposessionAddressLine2,
            reposessionAddressLine3:this.reposessionAddressLine3,
            reposessionAddressLine4:this.reposessionAddressLine4,                      
          },
        })
        this.isReposession = false
        this.reposessionAccountNo = ''
        this.reposessionWireTitle = ''        
        this.firstName = ''
        this.lastName = ''
        this.email = ''
        this.phone = ''
        this.departament = null
        this.boltId = ''
        this.uberId = ''
        this.isEmployed = false
        this.isUnder26 = false
        this.isStudent = false
        this.uberEatsId =''
        this.freeId = ''
        this.deligoId = ''
        this.street = ''
        this.streetExtraInfo = ''
        this.city = ''
        this.licenceNumber = ''
        this.abroadBankAccount = false
        this.cashPayments = false
        this.accountNo = '';
        this.password = null;
        this.carMake = '';
        this.carPlate = '';  
        this.reposessionAddressLine1= ''
        this.reposessionAddressLine2 = ''
        this.reposessionAddressLine3 = ''
        this.reposessionAddressLine4 = ''  
        this.paymentSchedule=null
        this.cooperationType=null
        this.zusType = null
        this.dialogCreate = false    
      } catch (e) {
          this.toggleLoading(false);
          console.log(e)
          Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
      await this.loadDrivers()
    },            
    async loadDrivers() {
      this.loading = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.options        
        const drivers = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/drivers?sort=${sortBy.length ? sortBy : 'lastName'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}&s=${this.search}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.drivers = drivers.data.data.items
        this.itemsPerPage = drivers.data.data.meta.itemsPerPage
        this.page = drivers.data.data.meta.currentPage
        this.totalRecords = drivers.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.loading = false
    },        
  }  
}
</script>
