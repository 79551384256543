<template>
    <v-card>
        <v-card-title>
            Wypłaty
            <v-spacer></v-spacer>
            <v-text-field label="Tydzień" v-model="weekNo" type="week" @change="loadPayments()"></v-text-field>
            <v-spacer></v-spacer>
            <v-select v-model="settlementType" :items="settlementTypes" @change="loadPayments()" item-text="text" item-value="id" label="Rodzaj Rozliczenia">
            </v-select>
            <v-spacer></v-spacer>
            <v-select @change="loadPayments()" label="Oddział" v-model="departament" :items="departaments"
                item-text="departamentName" item-value="id"></v-select>
            <v-spacer></v-spacer>
    <v-text-field v-model="search" append-icon="mdi-magnify" label="Szukaj" single-line hide-details></v-text-field>
    <v-spacer></v-spacer>
        </v-card-title>
        <v-data-table :item-class="row_classes" v-model="selected" show-select :headers="headers" :items="payments" :options.sync="options"
            :server-items-length="totalRecords" :loading="loading" :search="search" class="elevation-1"></v-data-table>
            <v-footer class="justify-center">
                <v-btn class="warning" @click="markAsPaid(false)">
                    Oznacz zaznaczone jako Opłacone
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="success" @click="markAsPaid(true)">
                    Oznacz Wszystkie jako Opłacone
                </v-btn>                
                <v-spacer></v-spacer>
                <v-btn class="error" @click="cancelSettlement()">
                    Cofnij Rozliczenie
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="primary" @click="downloadXLSX()">
                    Pobierz XLSX
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="primary" @click="downloadCsv()">
                    Pobierz Koszyk Przelewów
                </v-btn>
            </v-footer>
    </v-card>
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
import * as reader from 'xlsx'
import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(weekOfYear)
export default {
    name: "Payments",
    data: () => ({
        search: '',
        options: {},
        totalRecords: 0,
        departaments: [],
        departament: '',        
        loading: false,
        headers: [
            {
                text: 'Imię',
                align: 'start',
                value: 'firstName',
                sortable:true
            },
            {
                text: 'Nazwisko',
                align: 'start',
                value: 'lastName',
                sortable: true
            },            
            {
                text: 'Numer Konta',
                align: 'start',
                value: 'accountNo', 
                sortable: false,               
            },            
            {
                text: 'Wypłata Zleceniówka',
                align: 'start',
                value: 'totalZus',
            },
            {
                text: 'Wypłata Wynajem',
                align: 'start',
                value: 'totalRent',
            },
            {
                text: 'Wypłata Zwrot Kosztów',
                align: 'start',
                value: 'totalReturn',
            },
            {
                text: 'Koszt Rozliczenia',
                align: 'start',
                value: 'settlementCost',
            }, 
            {
                text: 'Koszt Administracyjno-Księgowy',
                align: 'start',
                value: 'administrationTax',
            },
            {
                text: 'Łącznie Do wypłaty',
                align: 'start',
                value: 'total',
                sortable: false
            },                                    
            {
                text: 'Rozliczanie Gotówką?',
                align: 'start',
                value: 'cashPayments'  ,
                sortable: true              
            },
            {
                text: 'Opłacone?',
                align: 'start',
                value: 'isPaid',
                sortable: true
            },  
            {
                text: 'Konto Za granicą/ Nieuwzględnione w koszyku?',
                align: 'start',
                value: 'abroadBankAccount',
                sortable: true
            },                                    
            
        ],
        payments: [],
        selected: [],
        weekNo: dayjs().format('YYYY') + '-W' + (dayjs().week()-1),
        settlementType: 'week',
        settlementTypes: [{
            id: 'week',
            text: 'Tygodniowe',
            selected:true,
        },
        {
            id: 'two_week',
            text: '2 Tygodnie'
        },
        {
            id: 'month',
            text: 'Miesięczne'
        }
        ],         
    }),
    watch: {
        options: {
            async handler() {
                if(!this.departament){
                    await this.getDepartamentSelect()
                }            
                await this.loadPayments()                
            },
            deep: true,
        },
        async search() {
            this.options.page = 1 
            await this.loadPayments()
        }
    },
    methods: {
        row_classes(item) {
            if (item.isPaid == 'Tak') {
                return "green";
            }
        },        
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async downloadXLSX(){
            await this.toggleLoading(true);
            try{
                const response = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/settlements/get-payments-xlsx/${this.weekNo}/${this.settlementType}/${this.departament}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });   
                const data = response.data.data.map((item)=>{
                    return{
                        driverName: item.driver.firstName,
                        lastName: item.driver.lastName,
                        accountNo: item.driver.accountNo,                        
                        totalZus: item.totalZus,
                        totalRent: item.totalRent,
                        totalReturn: item.totalReturn,
                        settlementCost: item.settlementCost,
                        administrationTax: item.administrationTax,
                        total: item.total,                        
                        cashPayments: item.cashPayments,
                        isPaid: item.isPaid,
                        abroadBankAccount: item.abroadBankAccount,
                    }
                })             
                const worksheet = reader.utils.json_to_sheet(data);
                const workbook = reader.utils.book_new();
                reader.utils.book_append_sheet(workbook, worksheet, "Raport");
                reader.utils.sheet_add_aoa(worksheet, [
                    ["Imię",
                    "Nazwisko",
                    "Numer Konta",
                    "Zleceniówka",
                    "Wynajem",
                    "Zwrot Kosztów",
                    "Koszt Rozliczenia",
                    "Kosz Administracyjno-Księgowy",
                    "Do wypłaty",
                    "Rozliczanie Gotówką?",
                    "Wypłacono?",
                    "Konto za granicą?"]], { origin: "A1" });
                reader.writeFile(workbook, dayjs().unix() + ".xlsx", { compression: true });   
            }catch(e){
                console.log(e)
                Swal.fire("Błąd", e.response.statusText, "error");                
            }
            await this.toggleLoading(false);
        },
        async cancelSettlement(){
            // if (this.departament == null) {
            //     Swal.fire("Błąd", "Wbierz oddział", "error");
            //     return false;
            // }                         
            try {
                Swal.fire({
                    title: "Cofnąć Rozliczenie?",
                    text: "",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Tak",
                    cancelButtonText: "Anuluj",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await this.toggleLoading(true);
                        await axios({
                            method: "delete",
                            url: process.env.VUE_APP_ROOT_API + "/settlements/cancel",
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                            data: {
                                weekStr: this.weekNo,
                                departament: this.departament,
                                paymentSchedule: this.settlementType                                                                
                            }
                        });     
                        await this.toggleLoading(false);                       
                        await this.loadPayments()                        
                    }
                })
            } catch (e) {
                await this.toggleLoading(false);    
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            await this.toggleLoading(false);                       
        },
        async getDepartamentSelect() {
            await this.toggleLoading(true);
            try {
                let dep = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/departaments/list-departaments-for-user/${localStorage.getItem('userid')}?itemsPerPage=100&sort=departamentName&direction=false`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                dep.data.data.items.push({
                    id:null,
                    departamentName:"Wszystkie"
                })
                this.departament = null
                this.departaments = dep.data.data.items;
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            await this.toggleLoading(false);
        },        
        async loadPayments() {
            this.loading = true
            try {
                let { sortBy, sortDesc, page, itemsPerPage } = this.options
                const payments = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/settlements/get-payments/${this.weekNo}/${this.settlementType}/${this.departament}?sort=${sortBy.length ? sortBy : 'totalRent'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}&s=${this.search}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });                                
                payments.data.data.items.forEach((item) => {   
                    item.total = (((item.totalRent || 0) + (item.totalZus || 0) + (item.totalReturn || 0)) / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" });                                                                                
                    item.firstName = item.driver.firstName
                    item.lastName =  item.driver.lastName
                    item.accountNo = item.driver.accountNo
                    item.cashPayments = item.driver.cashPayments?'Tak':'Nie'
                    item.isPaid = item.isPaid ? 'Tak' : 'Nie'
                    item.totalZus = (item.totalZus / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" });                          
                    item.administrationTax = (item.administrationTax / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" });                          
                    item.settlementCost = (item.settlementCost / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" });                          
                    item.totalRent = (item.totalRent / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" });                          
                    item.totalReturn = (item.totalReturn / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" });   
                    item.abroadBankAccount = item.driver.abroadBankAccount ? 'Tak' : 'Nie'                      
                })          
                this.payments = payments.data.data.items
                this.itemsPerPage = payments.data.data.meta.itemsPerPage
                this.page = payments.data.data.meta.currentPage
                this.totalRecords = payments.data.data.meta.totalItems
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            this.loading = false
        },
        async markAsPaid(isAll = false) {
            // if (this.departament == null) {
            //     Swal.fire("Błąd", "Wbierz oddział", "error");
            //     return false;
            // }             
            
            try {
                Swal.fire({
                    title: "Oznaczyć zaznaczone jako zapłacone",
                    text: "",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Tak",
                    cancelButtonText: "Anuluj",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await this.toggleLoading(true);
                        await axios({
                            method: "post",
                            url: process.env.VUE_APP_ROOT_API + "/settlements/mark-as-paid/",
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                            data: {
                                weekStr: this.weekNo,
                                departament:this.departament,
                                id: this.selected.map(item => item.id),
                                markAll: isAll
                            }
                        });
                        this.selected = []
                        await this.toggleLoading(false);
                        await this.loadPayments()
                    }
                });
            } catch (e) {
                console.log(e)
                await this.toggleLoading(false);
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            await this.toggleLoading(false);
        },
        
        async downloadCsv() {
            // if (this.departament == null) {
            //     Swal.fire("Błąd", "Wbierz oddział", "error");
            //     return false;
            // }                  
            const banks = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + `/config/banks?sort=accountNo&direction=false&page=1&limit=100`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });     
            let banksStr = ''
            banks.data.data.items.forEach((item)=>{
                banksStr += `<option value=${item.accountNo}>${item.accountNo}</option>`
            })       
            const { value: formValues } = await Swal.fire({
                title: 'Wybierz Konta',
                html:
                    `<div style="width:100%;">
                        <label style="width:100%;">Wybierz Konto Dla Zleceniówki</label><select id="swal-input1"  class="swal2-input">${banksStr} </select>
                    </div>
                    <div style="width:100%;">
                        <label style="width:100%;">Wybierz Konto Dla Wynajmu</label><select id="swal-input2" label="Wybierz Konto Dla Wynajmu Auta" class="swal2-input">${banksStr} </select>
                    </div>
                    <div style="width:100%;">
                        <label style="width:100%;">Wybierz Konto Dla Kosztów</label><select id="swal-input3" label="Wybierz Konto Dla Zwrotu Kosztów" class="swal2-input">${banksStr} </select> 
                    </div>`,
                focusConfirm: false,
                preConfirm: () => {
                    return [
                        document.getElementById('swal-input1').value,
                        document.getElementById('swal-input2').value,
                        document.getElementById('swal-input3').value
                    ]
                }
            })            
            try{
                await this.toggleLoading(true);        
                const file = await axios({
                    method: "post",
                    url: process.env.VUE_APP_ROOT_API + "/settlements/generate-payroll",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        weekStr: this.weekNo,
                        departament:this.departament,
                        type: this.settlementType,
                        zusBankNo: formValues[0],
                        rentBankNo: formValues[1],
                        costBankNo: formValues[2]
                    },
                    responseType: "blob"
                });
                let blob = new Blob([file.data], { type: 'application/zip' }),
                    url = window.URL.createObjectURL(blob)
                window.open(url)
                await this.toggleLoading(false);        
            }catch(e){
                console.log(e)
                await this.toggleLoading(false);        
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");                
            }
            await this.toggleLoading(false);        
        }        
    },
}
</script>
