<template>
  <v-card>
    <v-tabs>
      <v-tab>
        <v-icon left>
          mdi-sack-percent
        </v-icon>
        Konfiguracja Systemu i Stawek
      </v-tab>
      <v-tab @click="loadDepartaments()">
        <v-icon left>
          mdi-domain
        </v-icon>
        Oddziały
      </v-tab>   
      <v-tab @click="loadBankAccounts()">
        <v-icon left>
          mdi-account-cash
        </v-icon>
        Konta Bankowe
      </v-tab>

      <v-tab-item>
        <v-card flat>          
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field v-model="smsApiKey" label="Klucz SMS API" required></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="tax_vat_fuel_range_1" type="number" label="I % Zwrotu VAT za Paliwo" required></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model="tax_vat_fuel_range_2" type="number" label="II % Zwrotu VAT za Paliwo" required></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model="tax_vat_fuel_range_3" type="number" label="III % Zwrotu VAT za Paliwo" required></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="tax_vat_full_range_1" type="number" label="I % Zwrotu VAT za Inne" required></v-text-field>
                </v-col>
                
                <v-col cols="12" md="4">
                  <v-text-field v-model="tax_vat_full_range_2" type="number" label="II % Zwrotu VAT za Inne" required></v-text-field>
                </v-col>
                
                <v-col cols="12" md="4">
                  <v-text-field v-model="tax_vat_full_range_3" type="number" label="III % Zwrotu VAT za Inne" required></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field v-model="tax_zus_student_hired" type="number" label="Stawka Zus Dla Zatrudnionego Poniżej 26 Roku Życia" required></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="tax_zus_student_not_hired" type="number" label="Stawka Zus Dla Niezatrudnionego Studenta Poniżej 26 Roku Życia" required></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="tax_zus_no_student_hired" type="number" label="Stawka Zus Dla Zatrudnionego" required></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="tax_zus_no_student_not_hired" type="number" label="Stawka Zus Dla Niezatrudnionego" required>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="totalZus" label="Kwota Zleceniówki" type="number" required>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="minimalWire" label="Minimalna Kwota przelewu" type="number" required>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="administrationTax" label="Opłata Księgowo-Administracyjna" type="number" required>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="companyDataRow1" :rules="[v => v.length <= 35 || 'Max 35 Znaków']" label="Dane Firmy do przelewu - Wiersz1" counter="35" required>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="companyDataRow2" :rules="[v => v.length <= 35 || 'Max 35 Znaków']" label="Dane Firmy do przelewu - Wiersz2"
                    counter="35" required>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="companyDataRow3" :rules="[v => v.length <= 35 || 'Max 35 Znaków']" label="Dane Firmy do przelewu - Wiersz3"
                    counter="35" required>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="companyDataRow4" :rules="[v => v.length <= 35 || 'Max 35 Znaków']" label="Dane Firmy do przelewu - Wiersz4"
                    counter="35" required>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="zusWireTitle" :rules="[v => v.length <= 35 || 'Max 35 Znaków']"  counter="35"  label="Tytuł Przelewu z tytułu umowy zlecenie"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="rentWireTitle" :rules="[v => v.length <= 35 || 'Max 35 Znaków']"  counter="35" label="Tytuł Przelewu z tytułu wynajmu samochodu"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="costWireTitle" :rules="[v => v.length <= 35 || 'Max 35 Znaków']" counter="35"  label="Tytuł Przelewu z tytułu zwrotu kosztów"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="b2bWireTitle" :rules="[v => v.length <= 35 || 'Max 35 Znaków']"  counter="35" label="Tytuł Przelewu z tytułu B2B"></v-text-field>
                </v-col>
                <v-divider></v-divider>
                <v-col cols="12" md="12">
                  <v-btn style="width:100%;" class="primary" @click="updateConfig()">Zapisz</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-tab-item>
      <v-tab-item  :eager="true">
        <v-card flat>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action }} Oddziału</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Nazwa Oddziału" v-model="departamentName" required placeholder="Nazwa Oddziału"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
              </v-col>
              </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Anuluj
          </v-btn>
          <v-btn color="blue darken-1" text @click="createDepartament()">
            Zapisz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
              <v-card-title>
                Oddziały
                <v-spacer></v-spacer>
                <v-btn class="primary" @click="showDialog()">Dodaj</v-btn>
              </v-card-title>
          <v-data-table :headers="headers" :items="departamens" :options.sync="options"
            :server-items-length="totalRecords" :loading="loading" class="elevation-1">
            <template v-slot:item.editable="{ item }">
              <v-btn class="warning" @click="showDialog(item.id)">
                <v-icon small class="pt-1">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.deleteable="{ item }">
              <v-btn class="error" @click="deleteDepartament(item.id)">
                <v-icon small class="pt-1">mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>   
      <v-tab-item :eager="true">
        <v-card flat>
          <v-dialog v-model="dialogBank" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Konta Bankowe</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field label="Numer Konta" v-model="accountNo" required placeholder="Numer Konta"
                        :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Anuluj
                </v-btn>
                <v-btn color="blue darken-1" text @click="saveBankAccount()">
                  Zapisz
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-card-title>
            Konta Bankowe
            <v-spacer></v-spacer>
            <v-btn class="primary" @click="showBankAccountDialog()">Dodaj</v-btn>
          </v-card-title>
          <v-data-table :headers="headersBank" :items="banks" :options.sync="optionsBank" :server-items-length="totalRecordsBanks"
            :loading="loadingBank" class="elevation-1">
            <template v-slot:item.editable="{ item }">
              <v-btn class="warning" @click="showBankAccountDialog(item.id)">
                <v-icon small class="pt-1">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.deleteable="{ item }">
              <v-btn class="error" @click="deleteBankAccount(item.id)">
                <v-icon small class="pt-1">mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";
export default {
  name: "Config",
  data: () => ({
    // tbl
    options:{},
    departamens:[],
    totalRecords:0,
    headers: [
      {
        text: 'Nazwa Oddziału',
        align: 'start',
        value: 'departamentName',
      },
      { text: "", value: "editable", sortable: false },
      { text: "", value: "deleteable", sortable: false },
    ],
    loading:false,   
    companyDataRow1:'',
    companyDataRow2: '',
    companyDataRow3: '',
    companyDataRow4: '',
    zusWireTitle: '',
    rentWireTitle: '',
    costWireTitle: '',
    b2bWireTitle: '',
    // banks
    currentBankId:null,
    accountNo:'',
    optionsBank: {},
    banks: [],    
    totalRecordsBanks: 0,
    headersBank: [
      {
        text: 'Numer Konta',
        align: 'start',
        value: 'accountNo',
      },
      { text: "", value: "editable", sortable: false },
      { text: "", value: "deleteable", sortable: false },
    ],
    loadingBank: false,     
    dialogBank:false,
    //  config
    smsApiKey:'',
    administrationTax:0,
    tax_vat_fuel_range_1:0,
    tax_vat_fuel_range_2:0,
    tax_vat_fuel_range_3:0,
    tax_vat_full_range_1:0,
    tax_vat_full_range_2:0,
    tax_vat_full_range_3:0,
    tax_zus_student_hired:0,
    tax_zus_student_not_hired:0,
    tax_zus_no_student_hired:0,
    tax_zus_no_student_not_hired:0,
    totalZus:0,
    minimalWire:0,
    // create
    departamentName:'',
    currentDepartamentId:null,
    action:'Tworzenie',
    dialog:false,
  }),
  watch: {
    options: {
      async handler() {
        await this.loadDepartaments()
      },
      deep: true,
    },
  },   
  async created(){
    await this.loadConfig()    
  },
  methods:{
    async showDialog(id) {      
      this.action = 'Tworzenie'
      this.dialog = true
      this.currentDepartamentId = id?id:null
      if(this.currentDepartamentId){
        try {
          let dept = await axios({
            method: "get",
            url: process.env.VUE_APP_ROOT_API + "/departaments/get-departament/" + id,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
          this.departamentName = dept.data.data.departamentName
        }catch(e){
          console.log(e);
          this.dialog = false;
          Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
        }
      }
    },  
    async showBankAccountDialog(id) {      
      this.dialogBank = true
      this.currentBankId = id ? id : null
      if (this.currentBankId) {
        try {
          let bank = await axios({
            method: "get",
            url: process.env.VUE_APP_ROOT_API + "/config/banks/" + id,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
          this.accountNo = bank.data.data.accountNo
        } catch (e) {
          console.log(e);
          this.dialogBank = false;
          Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
        }
      }
    },       
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },  
    async validateConfigForm(){
      if(!this.companyDataRow1.trim().length){
        throw ({response:{
          statusText:'Uzupełnij Dane Firmy 1'
        }})
      }
      if(!this.companyDataRow2.trim().length){
        throw ({response:{
          statusText:'Uzupełnij Dane Firmy 2'
        }})
      }
      if(!this.companyDataRow3.trim().length){
        throw ({response:{
          statusText:'Uzupełnij Dane Firmy 3'
        }})
      }
      if(!this.companyDataRow4.trim().length){
        throw ({response:{
          statusText:'Uzupełnij Dane Firmy 4'
        }})
      }
      if(!this.zusWireTitle.trim().length){
        throw ({response:{
          statusText:'Uzupełnij Nazwę Przelewu - Zlecenie'
        }})
      }
      if(!this.rentWireTitle.trim().length){
        throw ({response:{
          statusText:'Uzupełnij Nazwę Przelewu - Wynajem'
        }})
      }
      if(!this.costWireTitle.trim().length){
        throw ({response:{
          statusText:'Uzupełnij Nazwę Przelewu - Zwrot Kosztów'
        }})
      }
      if(!this.b2bWireTitle.trim().length){
        throw ({response:{
          statusText:'Uzupełnij Nazwę Przelewu - B2B'
        }})
      }
      if(!this.smsApiKey.trim().length){
        throw ({response:{
          statusText:'Uzupełnij Klucz SMS API'
        }})
      }
    },
    async createDepartament() {
      await this.toggleLoading(true);
      let method = "";
      let url = "";
      if (this.currentDepartamentId) {
        method = "patch";
        url = process.env.VUE_APP_ROOT_API + "/departaments/" + this.currentDepartamentId;
      } else {
        method = "post";
        url = process.env.VUE_APP_ROOT_API + "/departaments";
      }
      try {
        if(!this.departamentName.trim().length){
          throw({response:{statusText:'Uzupełnij Nazwę Oddziału!'}})
        }
        await axios({
          method: method,
          url: url,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            departamentName: this.departamentName,
          },
        });
        this.departamentName = "";
        this.dialog = false
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
      await this.loadDepartaments()
    },
    async saveBankAccount() {
      await this.toggleLoading(true);
      let method = "";
      let url = "";
      if (this.currentBankId) {
        method = "patch";
        url = process.env.VUE_APP_ROOT_API + "/config/banks/" + this.currentBankId;
      } else {
        method = "post";
        url = process.env.VUE_APP_ROOT_API + "/config/banks";
      }
      try {
        if (!this.accountNo.trim().length) {
          throw ({ response: { statusText: 'Uzupełnij Numer Konta!' } })
        }        
        await axios({
          method: method,
          url: url,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            accountNo: this.accountNo,
          },
        });
        this.accountNo = "";
        this.dialogBank = false
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
      await this.loadBankAccounts()
    },        
    async loadDepartaments() {
      this.loading = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.options        
        const dept = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/departaments/list-departaments?sort=${sortBy.length ? sortBy : 'departamentName'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.departamens = dept.data.data.items
        this.itemsPerPage = dept.data.data.meta.itemsPerPage
        this.page = dept.data.data.meta.currentPage
        this.totalRecords = dept.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.loading = false
    },
    async loadBankAccounts() {
      this.loadingBank = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.optionsBank        
        const banks = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/config/banks?sort=${sortBy.length ? sortBy : 'accountNo'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.banks = banks.data.data.items
        this.itemsPerPage = banks.data.data.meta.itemsPerPage
        this.page = banks.data.data.meta.currentPage
        this.totalRecordsBanks = banks.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.loadingBank = false
    },  
    async deleteBankAccount(id) {
      await this.toggleLoading(true);
      try {
        Swal.fire({
          title: "Usunąć Numer Konta?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "/config/banks/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.loadBankAccounts()
          }
        });
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);      
    },        
    async deleteDepartament(id){   
      await this.toggleLoading(true); 
        try {
          Swal.fire({
            title: "Usunąć Oddział?",
            text: "",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Tak",
            cancelButtonText: "Anuluj",
          }).then(async (result) => {
            if (result.isConfirmed) {
              await axios({
                method: "delete",
                url: process.env.VUE_APP_ROOT_API + "/departaments/" + id,
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }); 
              await this.loadDepartaments()             
            }
          });
        } catch (e) {
          console.log(e)
          Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
        }
      await this.toggleLoading(false);
      await this.loadDepartaments()
    },            
    async updateConfig(){
      await this.toggleLoading(true);
      try {
        await this.validateConfigForm()
        await axios({
          method: 'patch',
          url: process.env.VUE_APP_ROOT_API + "/config",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            smsApiKey: this.smsApiKey,
            tax_vat_fuel_range_1: this.tax_vat_fuel_range_1,
            tax_vat_fuel_range_2: this.tax_vat_fuel_range_2,
            tax_vat_fuel_range_3: this.tax_vat_fuel_range_3,
            tax_vat_full_range_1: this.tax_vat_full_range_1,
            tax_vat_full_range_2: this.tax_vat_full_range_2,
            tax_vat_full_range_3: this.tax_vat_full_range_3,
            tax_zus_student_hired: this.tax_zus_student_hired,
            tax_zus_student_not_hired: this.tax_zus_student_not_hired,
            tax_zus_no_student_hired: this.tax_zus_no_student_hired,
            tax_zus_no_student_not_hired: this.tax_zus_no_student_not_hired,
            totalZus: this.totalZus,
            administrationTax: this.administrationTax,
            minimalWire: this.minimalWire,
            companyDataRow1: this.companyDataRow1,
            companyDataRow2: this.companyDataRow2,
            companyDataRow3: this.companyDataRow3,
            companyDataRow4: this.companyDataRow4,
            zusWireTitle: this.zusWireTitle,
            rentWireTitle: this.rentWireTitle,
            costWireTitle: this.costWireTitle,
            b2bWireTitle: this.b2bWireTitle,
          },
        });
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }       
      await this.toggleLoading(false);
      await this.loadConfig()
    },
    async loadConfig(){
      await this.toggleLoading(true);
      try {
        let config = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/config",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });  
        this.smsApiKey=config.data.data.smsApiKey
        this.tax_vat_fuel_range_1 = config.data.data.tax_vat_fuel_range_1
        this.tax_vat_fuel_range_2 = config.data.data.tax_vat_fuel_range_2
        this.tax_vat_fuel_range_3 = config.data.data.tax_vat_fuel_range_3
        this.tax_vat_full_range_1 = config.data.data.tax_vat_full_range_1
        this.tax_vat_full_range_2 = config.data.data.tax_vat_full_range_2
        this.tax_vat_full_range_3 = config.data.data.tax_vat_full_range_3
        this.administrationTax = config.data.data.administrationTax / 100
        this.tax_zus_student_hired = config.data.data.tax_zus_student_hired/100
        this.tax_zus_student_not_hired = config.data.data.tax_zus_student_not_hired/100
        this.tax_zus_no_student_hired = config.data.data.tax_zus_no_student_hired/100
        this.tax_zus_no_student_not_hired = config.data.data.tax_zus_no_student_not_hired/100
        this.totalZus = config.data.data.totalZus/100
        this.minimalWire = config.data.data.minimalWire / 100  
        this.companyDataRow1 = config.data.data.companyDataRow1
        this.companyDataRow2 = config.data.data.companyDataRow2
        this.companyDataRow3 = config.data.data.companyDataRow3
        this.companyDataRow4 = config.data.data.companyDataRow4
        this.zusWireTitle = config.data.data.zusWireTitle
        this.rentWireTitle = config.data.data.rentWireTitle
        this.costWireTitle = config.data.data.costWireTitle
        this.b2bWireTitle = config.data.data.b2bWireTitle           
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);      
    }
  }

}
</script>
