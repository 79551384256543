<template>
    <v-card>
        <v-card-title>
            Historia Importu
            <v-spacer></v-spacer>
        </v-card-title>
        <v-data-table :headers="headers" :items="imported" :options.sync="options" :server-items-length="totalRecords"
            :loading="loading" :search="search" class="elevation-1">
            <template v-slot:item.actions="{ item }">
                <v-btn v-if="!item.status" class="error" @click="undoImport(item.id)">
                    Cofnij Import
                </v-btn>
                <v-btn v-if="item.status"  disabled class="warning">
                    Anulowano Import
                </v-btn>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(weekOfYear)
export default {
    name: "ImportHistory",
    data: () => ({
        search: '',
        options: {},
        totalRecords: 0,
        loading: false,
        headers: [
            { text: 'Data Importu', value: 'importDate' },
            {
                text: 'Nazwa Pliku',
                align: 'start',
                value: 'fileName',
            },
            { text: "", value: "actions", sortable: false },
        ],
        imported: []
    }),
    watch: {
        options: {
            async handler() {
                await this.loadImportHistory()
            },
            deep: true,
        },
        async search() {
            this.options.page = 1 
            await this.loadImportHistory()
        }
    },
    methods: {
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async loadImportHistory() {
            this.loading = true
            try {
                let { sortBy, sortDesc, page, itemsPerPage } = this.options
                const imported = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/settlements/get-import-history?sort=${sortBy.length ? sortBy : 'importDate'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}&s=${this.search}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                imported.data.data.items.forEach((item) => {
                    item.importDate = dayjs(item.importDate).format('YYYY-MM-DD HH:mm')
                })
                this.imported = imported.data.data.items
                this.itemsPerPage = imported.data.data.meta.itemsPerPage
                this.page = imported.data.data.meta.currentPage
                this.totalRecords = imported.data.data.meta.totalItems
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            this.loading = false
        },
        async undoImport(id) {
            await this.toggleLoading(true);
            try {
                Swal.fire({
                    title: "Cofnąć import?",
                    text: "",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Tak",
                    cancelButtonText: "Anuluj",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await axios({
                            method: "delete",
                            url: process.env.VUE_APP_ROOT_API + "/settlements/undo-import/" + id,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        });
                        await this.loadImportHistory()
                    }
                });
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            await this.toggleLoading(false);
        }
    },
}
</script>
