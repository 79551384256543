<template>
  <v-app id="inspire">
    <v-app-bar app v-if="user == 'true'" color="blue lighten-2">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title></v-toolbar-title>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        style="z-index: 9999"
        color="deep-purple accent-4"
      ></v-progress-linear>
    </v-app-bar>
    <v-overlay :value="overlay" style="z-index: 9999"></v-overlay>

    <v-navigation-drawer v-model="drawer" fixed temporary v-if="user == 'true'">
      <v-sheet color="blue lighten-2" class="pa-4">
        <v-avatar class="mb-4" color="black darken-1" size="64" >
                <img src="../public/logo.jpg" alt="John">
        </v-avatar>
      
        <div>BTP SP. Z.O.O</div>
      </v-sheet>
      <v-list>
        <v-list-item v-for="[icon, text, link] in links" :key="icon" :to="link" active-class="blue lighten-2--text ">
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!--  -->
      <template v-slot:append >
        <div class="pa-2">
          <v-btn color="blue lighten-2" block @click="logout()"> Wyloguj </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-content>      
      <router-view />      
    </v-content>
<v-footer padless color="blue lighten-2">
  <v-col class="text-center" cols="12">
    {{ new Date().getFullYear() }} — <strong>BTP SP. Z.O.O</strong>
  </v-col>
</v-footer>
  </v-app>
</template>
<script>
// const axios = require("axios");
    // axios.interceptors.response.use(response => {
    //   console.log(response);
    //   return response;
    // }, error => {
    //   console.log(error);
    //   if (error.response.status === 401) {
    //     localStorage.setItem("user", false);
    //     localStorage.setItem("token", null);
    //     localStorage.setItem("role", null);
    //     localStorage.setItem("userid", null);
    //     localStorage.setItem("whid", null);
    //     window.location.href = "/";
    //   } else {
    //     return error.response.status
    //   }
    //   return Promise.reject(error);
    // })  
export default {
  name: "App",
  data: () => ({
    user: false,
    drawer: null,
    loading: false,
    overlay: false,
    links: []
  }),
  mounted() {    
    this.user = localStorage.getItem("user");
    window.addEventListener("toggleLoading", (event) => {
      this.loading = event.detail.loading == "true" ? true : false;
      this.overlay = event.detail.overlay == "true" ? true : false;
    });
    this.user = localStorage.getItem("user");
    if (localStorage.getItem("role") == "admin") {
      this.links = [
        ["mdi-monitor-dashboard", "Dashboard", "dashboard"],
        ["mdi-account-credit-card", "Rozliczenia", "settlements"],
        ["mdi-account-alert", "Nieprzypisane Rozliczenia", "import-missing"],
        ["mdi-cash-clock", "Wypłaty", "payments"],
        ["mdi-database-import", "Importer", "import"],
        ["mdi-history", "Historia Importów", "import-history"],
        ["mdi-clipboard-text-clock", "Dane z Importu", "import-preview"],
        ["mdi-receipt-text-check", "Faktury do Edycji", "invoices"],
        ["mdi-receipt-text", "Rachunki", "receipts"],
        ["mdi-credit-card", "DKV", "dkv"],
        ["mdi-car-info", "Flota", "cars"],
        ["mdi-card-account-details", "Kierowcy", "drivers"],
        ["mdi-account-convert", "Komornicy", "reposession"],
        ["mdi-account-multiple-check", "Kierowcy Do Akceptacji", "drivers-to-accept"],        
        ["mdi-account-edit", "Użytkownicy", "users"],
        ["mdi-cog", "Konfiguracja", "config"],
      ]
    }else{
      this.links = [        
        ["mdi-account-credit-card", "Rozliczenia", "settlements"],
        ["mdi-account-alert", "Nieprzypisane Rozliczenia", "import-missing"],
        ["mdi-cash-clock", "Wypłaty", "payments"],
        ["mdi-database-import", "Importer", "import"],
        ["mdi-history", "Historia Importów", "import-history"],
        ["mdi-clipboard-text-clock", "Dane z Importu", "import-preview"],
        ["mdi-receipt-text-check", "Faktury do Edycji", "invoices"],
        ["mdi-receipt-text", "Rachunki", "receipts"],
        ["mdi-credit-card", "DKV", "dkv"],
        ["mdi-car-info", "Flota", "cars"],
        ["mdi-card-account-details", "Kierowcy", "drivers"],
        ["mdi-account-convert", "Komornicy", "reposession"],
        ["mdi-account-multiple-check", "Kierowcy Do Akceptacji", "drivers-to-accept"],        
        ["mdi-account-edit", "Użytkownicy", "users"],
        ["mdi-cog", "Konfiguracja", "config"],
      ]
    }    
  },
  methods: {
    logout() {
      localStorage.setItem("token", null);
      localStorage.setItem("user", false);
      window.location.href = "/";
    },
  },
};
</script>