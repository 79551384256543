<!-- eslint-disable vue/valid-v-slot -->
<template>
    <v-card>
        <v-card-title>
            Rachunki
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Szukaj" single-line hide-details></v-text-field>
            <v-spacer></v-spacer>
           
            <v-text-field label="Miesiąc" v-model="monthNo" type="date" @change="loadInvoices()"></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="invoices" :search="search"
            :loading="loading" class="elevation-1">
            <template v-slot:item.actions="{ item }">
                <v-btn class="primary" @click="downloadSettlement(item.rentIds,item.did)">
                    <v-icon small class="pt-1">mdi-download</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <div class="text-center">
            <v-btn class="primary" @click="downloadAll()">Pobierz Wszystkie</v-btn>
        </div>        
    </v-card>
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(weekOfYear)

const date = new Date();
date.setMonth(date.getMonth() - 1);
export default {
    name: "InvoicesView",
    data: () => ({
        monthNo: date
            .toISOString()
            .substr(0, 10),
        search: '',
        idsArr: [],
        options: {},
        totalRecords: 0,
        loading: false,
        headers: [
            {
                text: 'Imię',
                align: 'start',
                value: 'firstName',
                sortable: false,
            },
            {
                text: 'Nazwisko',
                align: 'start',
                value: 'lastName',
                sortable: false,
            },                        
            { text: 'Data Rachunku', value: 'dateG', sortable: false, },
            {
                text: 'Kwota',
                align: 'start',
                value: 'totalRent',
                sortable: false,
            },
            {
                text: 'Podatek',
                align: 'start',
                value: 'tax',
                sortable: false,
            },
            { text: '', value: 'actions', sortable: false, },
        ],
        invoices: [],
    }),
    async created() {
        await this.loadInvoices()
    },
    methods: {
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async downloadSettlement(ids,driver) {
            await this.toggleLoading(true);
            const file = await axios({
                method: "post",
                url: process.env.VUE_APP_ROOT_API + "/settlements/download-invoice-admin",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                data: {
                    ids,
                    driverId:driver,
                },
                responseType: "blob"
            });
            let blob = new Blob([file.data], { type: 'application/pdf' }),
                url = window.URL.createObjectURL(blob)

            window.open(url)
            await this.toggleLoading(false);
        },
        async downloadAll(){
            await this.toggleLoading(true);
            const file = await axios({
                method: "post",
                url: process.env.VUE_APP_ROOT_API + "/settlements/download-invoice-all-admin",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                data:{
                    ids:this.idsArr
                },
                responseType: "blob"
            });
            let blob = new Blob([file.data], { type: 'application/zip' }),
                url = window.URL.createObjectURL(blob)

            window.open(url)
            await this.toggleLoading(false);
        },
        async loadInvoices() {  
            if(dayjs(this.monthNo).format('MM')>=dayjs().format('MM') && dayjs(this.monthNo).format('YYYY')>=dayjs().format('YYYY')){
                Swal.fire('Błąd', "Miesiąc musi być zakończony (Mniejszy od aktualnego)", "error")
                return false
            }          
            this.loading = true
            try {   
                this.invoices = []    
                this.idsArr = []         
                const invoices = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/settlements/get-all-invoices/${this.monthNo}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                if(!invoices.data.data.length){
                    this.invoices = []
                    this.loading = false
                    return false
                }
                invoices.data.data.forEach((item) => {                  
                    item.tax = (parseFloat(item.totalRent) * 0.085).toFixed(2)
                    this.idsArr.push({ ids:item.rentIds, driverId:item.did })
                })
                this.invoices = invoices.data.data    
                this.loading = false            
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
                this.loading = false
            }
            this.loading = false
        },
    },
}
</script>
