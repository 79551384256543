<template>  
  <v-card>
    <v-card-title>
      Dashboard
      <v-spacer></v-spacer>
            <v-text-field label="Tydzień" v-model="weekNo" type="week" @change="loadDashboard()"></v-text-field>
            <v-spacer></v-spacer>
            <v-select @change="loadDashboard()" label="Oddział" v-model="departament" :items="departaments"
              item-text="departamentName" item-value="id"></v-select>
    </v-card-title>
    <v-container>
      <v-simple-table>
          <template v-slot:default>
        <thead>
          <tr>
          <th>
            Aplikacja
          </th>
            <th>
              Wypłaty dla kierowców
            </th> 
             <th>Niewypłacono (Miesięczni)</th> 
            <th>
                Przelew
            </th> 
           
              <th>
                  Zysk
              </th>                
          </tr>                  
        </thead>
        <tbody>
            <tr
              v-for="item in mainSettlement"
              :key="item.name"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.price }}</td>
              <td>{{ item.unpaid }}</td>
              <td><v-text-field type="number" v-model="wire[item.name]" placeholder="Kwota Przelewu"></v-text-field></td>
              
              <td>{{ item.profit }}</td>
            </tr>
            <tr>
              <td>Łącznie Wynajem</td>
              <td>Koszt Rozliczenia</td>
              <td>Koszt Administracyjno-Księgowy</td>
              <td>Łącznie Aplikacje: </td>
              <td><span style="color:lightgreen;" >{{ totalWire }}</span>-<span style="color:red;">{{ totalPlatforms }}</span>=<span :style="{ color: parseFloat(totalApp) > 0 ? 'lightgreen' : 'red' }">{{ totalApp }}</span></td>
            </tr>
            <tr>
                <td>{{ carRents }}</td>
                <td>{{ totalSettlementCost }}</td>
                <td>{{totalAdministrationTax}}</td>
                <td>Łączny Zysk:</td>
                <td :style="{color: parseFloat(totalTotal)>0 ? 'lightgreen' :'red'}">{{ totalTotal }}</td>              
            </tr>
            <tr>
              <th>Niewypłacono Miesięczni</th>
              <td></td>
              <td></td>
              <td></td>
              <td style="color:red;">{{ totalUnpaid }}</td>
            </tr>              
              <tr>
                  <th>Aktywni FreeNow</th>
                  <th>Aktywni Bolt</th>
                  <th>Aktywni Uber</th>
                  <th>Aktywni UberEats</th>                  
                  <th>Aktywni Deligo</th>              
              </tr>               
                <tr>
                    <td>{{ driversFreeNow }}</td>
                    <td>{{ driversBolt }}</td>
                    <td>{{ driversUber }}</td>
                    <td>{{ driversUberEats }}</td>
                    <td>{{ driversDeligo }}</td>              
                </tr>                                      
        </tbody>
        </template>
        </v-simple-table>
        <div class="text-center" v-if="departament">
          <v-btn class="success" @click="saveWires()">Zapisz Przelewy</v-btn>
        </div>
        
    </v-container>
  </v-card>
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(weekOfYear)
export default {
  name: "Dashboard",  
  data: () => ({
    totalWire:0,
    totalApp:0,
    wire:[],
    mainSettlement:[],
    departaments: [],
    departament: '',  
    totalPlatforms:0,
    totalUnpaid:0,
    carRents:0,
    totalTotal:0,
    driversFreeNow:0,
    driversBolt: 0,
    driversUber: 0,
    driversUberEats: 0,
    driversDeligo: 0,
    wireBolt:0,
    wireUber: 0,
    wireUberEats: 0,
    wireDeligo: 0,
    wireFreeNow: 0,
    totalAdministrationTax:0,
    totalSettlementCost:0,
    weekNo: dayjs().format('YYYY') + '-W' + (dayjs().week() - 1),     
  }),
  async mounted(){
    await this.getDepartamentSelect()
    await this.loadDashboard()
  },
  
  methods:{
    async saveWires(){
      await this.toggleLoading(true)
      const wires = {
        freeNow: this.wire['FreeNow']||0,
        bolt: this.wire['Bolt'] || 0,
        uber: this.wire['Uber'] || 0,
        deligo: this.wire['Deligo'] || 0,
        uberEats: this.wire['UberEats'] || 0,
      }
      try{
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "/settlements/save-wires",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            ...wires,
            weekStr: this.weekNo,
            departament:this.departament
          }        
        });          
      }catch(e){
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");        
      }
      await this.toggleLoading(false)
      await this.loadDashboard()
    },
    async getDepartamentSelect() {
      await this.toggleLoading(true);
      try {
        let dep = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/departaments/list-departaments-for-user/${localStorage.getItem('userid')}?itemsPerPage=100&sort=departamentName&direction=false`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        dep.data.data.items.push({
          id: null,
          departamentName: "Wszystkie"
        })
        this.departament = null
        this.departaments = dep.data.data.items;
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
    },     
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },    
    async loadDashboard(){
      await this.toggleLoading(true);
      try {
        this.mainSettlement = []
        let dashboard = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/settlements/get-dashboard-for-admin/${this.weekNo}/${this.departament}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });          
        this.mainSettlement.push({
          name:'FreeNow',
          price: dashboard.data.data.totalFreeNow.toFixed(2),
          unpaid: dashboard.data.data.unpaidtotalFreeNow.toFixed(2),
          profit: (dashboard.data.data.wireFreeNow - dashboard.data.data.totalFreeNow).toFixed(2)
        })
        this.mainSettlement.push({
          name: 'Bolt',
          price: dashboard.data.data.totalBolt.toFixed(2),
          unpaid: dashboard.data.data.unpaidtotalBolt.toFixed(2),
          profit: (dashboard.data.data.wireBolt - dashboard.data.data.totalBolt).toFixed(2)
        })
        this.mainSettlement.push({
          name: 'Uber',
          price: dashboard.data.data.totalUber.toFixed(2),
          unpaid: dashboard.data.data.unpaidtotalUber.toFixed(2),
          profit: (dashboard.data.data.wireUber - dashboard.data.data.totalUber).toFixed(2)
        })
        this.mainSettlement.push({
          name: 'Deligo',
          price: dashboard.data.data.totalDeligo.toFixed(2),
          unpaid: dashboard.data.data.totalDeligo.toFixed(2),
          profit: (dashboard.data.data.wireDeligo - dashboard.data.data.totalDeligo).toFixed(2)
        }) 
        this.mainSettlement.push({
          name: 'UberEats',
          price: dashboard.data.data.totalUberEats.toFixed(2),
          unpaid: dashboard.data.data.unpaidtotalUberEats.toFixed(2),
          profit: (dashboard.data.data.wireUberEats -dashboard.data.data.totalUberEats).toFixed(2)
        }) 
        this.wire['FreeNow'] = dashboard.data.data.wireFreeNow
        this.wire['Bolt'] = dashboard.data.data.wireBolt
        this.wire['Uber'] = dashboard.data.data.wireUber
        this.wire['Deligo'] = dashboard.data.data.wireDeligo
        this.wire['UberEats']   = dashboard.data.data.wireUberEats  
        const totalWire  = (
          dashboard.data.data.wireFreeNow+
          dashboard.data.data.wireBolt +
          dashboard.data.data.wireUber +
          dashboard.data.data.wireDeligo +
          dashboard.data.data.wireUberEats 
        )
        this.totalWire = totalWire
        this.driversFreeNow = dashboard.data.data.driversFreeNow
        this.driversBolt = dashboard.data.data.driversBolt
        this.driversUber = dashboard.data.data.driversUber
        this.driversUberEats = dashboard.data.data.driversUberEats
        this.driversDeligo = dashboard.data.data.driversDeligo
        this.totalPlatforms=  dashboard.data.data.totalPlatforms.toFixed(2)
        this.totalSettlementCost = dashboard.data.data.totalSettlementCost.toFixed(2)  
        this.totalAdministrationTax = dashboard.data.data.totalAdministrationTax.toFixed(2)   
        this.carRents = dashboard.data.data.carRents.toFixed(2)   
        const totalApp = totalWire - dashboard.data.data.totalPlatforms
        this.totalApp = (totalApp).toFixed(2)  
        const totalUnpaid = (
          dashboard.data.data.unpaidtotalFreeNow+
          dashboard.data.data.unpaidtotalBolt +
          dashboard.data.data.unpaidtotalUber +
          dashboard.data.data.totalDeligo +
          dashboard.data.data.unpaidtotalUberEats
        )
        this.totalUnpaid =  totalUnpaid.toFixed(2) 
        this.totalTotal = (
          dashboard.data.data.totalSettlementCost+
          dashboard.data.data.totalAdministrationTax+
          dashboard.data.data.carRents+
          totalApp
        ).toFixed(2)            
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);      
    }
  }
}
</script>
