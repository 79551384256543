<template>
    <v-card>
        <v-card-title>
            Nieprzypisane Rozliczenia
            <v-spacer></v-spacer>
            <v-text-field  @change="loadMisssing()" label="Tydzień" v-model="weekNo" type="week"></v-text-field>
            <v-spacer></v-spacer>
            <v-select @change="loadMisssing()" v-model="fileType" :items="fileTypes" item-text="text" item-value="id" label="Plik">
            </v-select>
        </v-card-title>
        <v-data-table :headers="headers" :items="imported" :options.sync="options"
            :server-items-length="totalRecords" :loading="loading" class="elevation-1"></v-data-table>
            <v-footer class="justify-center">
                <v-btn class="primary" @click="reImport()">
                    Importuj Ponownie
                </v-btn>
            </v-footer>
    </v-card>    
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(weekOfYear)

export default {
    name: "Missing",
    data: () => ({
        search: '',
        options: {},
        totalRecords: 0,
        loading: false,
        headers: [
            { text: 'Data Importu', value: 'created_at' },
            {
                text: 'ID',
                align: 'start',
                value: 'IdName',
                sortable:false,
            },
        ],
        fileTypes:[
            {
                id: 'freenow',
                text: 'FreeNow',
                selected: true,
            },
            {
                id: 'freenow_new',
                text: 'FreeNow - Nowy',
                selected: true,
            },            
            {
                id: 'uber',
                text: 'Uber'
            },
            {
                id: 'uber_eats',
                text: 'Uber Eats'
            },
            {
                id: 'bolt',
                text: 'Bolt'
            },  
            {
                id: 'deligo',
                text: 'Deligo'
            } ,
            {
                id: 'dkv',
                text: 'DKV'
            }                                                
        ],
        fileType:'bolt',
        imported: [],
        weekNo: dayjs().format('YYYY') + '-W' + (dayjs().week()-1),
    }),
    watch: {
        options: {
            async handler() {
                await this.loadMisssing()
            },
            deep: true,
        },
        async search() {
            this.options.page = 1 
            await this.loadMisssing()
        }
    },
    methods: {
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async loadMisssing() {
            this.loading = true
            try {
                let { sortBy, sortDesc, page, itemsPerPage } = this.options
                const imported = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/settlements/get-missing-import/${this.fileType}/${this.weekNo}?sort=${sortBy.length ? sortBy : 'created_at'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}&s=${this.search}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                imported.data.data.items.forEach((item) => {
                    item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
                    switch(this.fileType){
                        case "uber":
                            item.IdName = item.uberId
                            break;                         
                        case "uber_eats":
                            item.IdName = item.uberId
                            break;                         
                        case "bolt":
                            item.IdName = item.boltId
                            break;                         
                        case "freenow":
                            item.IdName = item.freeId 
                            break;  
                        case "freenow_new":
                            item.IdName = item.freeId
                            break;                                
                        case "deligo":
                            item.IdName = item.deligoId                               
                            break;  
                        case "dkv":
                            item.IdName = item.deligoId
                            break;                                                     
                    }
                })
                this.imported = imported.data.data.items
                this.itemsPerPage = imported.data.data.meta.itemsPerPage
                this.page = imported.data.data.meta.currentPage
                this.totalRecords = imported.data.data.meta.totalItems
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            this.loading = false
        },
        async reImport() {            
            this.loading = true
            try {
                
                await axios({
                    method: "post",
                    url: process.env.VUE_APP_ROOT_API + "/settlements/reimport/" + this.fileType,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                await this.loadMisssing()                
            }catch(e){
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");                 
            }
            this.loading = false
        }
    },
}
</script>
