<template>
  <v-card>
    <v-dialog v-model="createDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{action}} Samochodu</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Marka" v-model="make" required placeholder="Marka"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Model" v-model="model" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                  placeholder="Model"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Tablica Rejestracyjna" v-model="plate" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                  placeholder="Tablica Rejestracyjna"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Rok Produkcji" v-model="year" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                  placeholder="Rok Produkcji"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Aktualny Przebieg"  :rules="[(v) => !!v || 'Uzupełnij Pole']" required v-model="odometer"
                  placeholder="Aktualny Przebieg"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Numer Boczny" placeholder="Numer Boczny" v-model="taxiNo" :rules="[(v) => !!v || 'Uzupełnij Pole']" required                  ></v-text-field>
              </v-col>
                    <v-col cols="12">
                      <v-checkbox label="Posiada Licencję?" v-model="hasLicence"></v-checkbox>
                    </v-col>  
                     <v-col cols="12">
                        <v-checkbox label="Oklejony?" v-model="isWrapped"></v-checkbox>
                      </v-col>                                  
              <v-col cols="12">
                <v-text-field label="Kaucja" placeholder="Kaucja" v-model="advance" type="number"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']" required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Koszt Najmu" placeholder="Koszt Najmu" v-model="rentPrice" type="number"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']" required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Kolor" placeholder="Kolor" v-model="color"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']" required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field type="date" label="Data Serwisu" placeholder="Data Serwisu" v-model="lastService" >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field type="date" label="Ubezpieczenie Ważne Do" placeholder="Ubezpieczenie Ważne Do" v-model="insuranceValidTo"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']" required>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field type="date" label="Przegląd Ważny Do" placeholder="Przegląd Ważny Do" v-model="inspectionValidTo"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']" required>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select label="Główny Kierowca" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="driver"
                  :items="drivers" item-text="driverName" item-value="id"></v-select>
              </v-col>
              <v-col cols="12">
                <v-select label="Drugi Kierowca" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="driver_second" :items="drivers"
                  item-text="driverName" item-value="id"></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="createDialog = false">
            Anuluj
          </v-btn>
          <v-btn color="blue darken-1" text @click="createCar()">
            Zapisz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogHistory" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogHistory = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Historia Pojazdu</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
        <v-timeline-item v-for="(item, i) in historyTimeline" :key="i" color="blue" icon="mdi-note" fill-dot>
          <v-card color="blue" dark>
            <v-card-title class="text-h6">
              {{item.created_at}}
            </v-card-title>
            <v-card-text class="dark text--primary">
              <p>
                <v-textarea v-model="descr[item.id]"></v-textarea>                
              </p>
              <v-row>
                <v-btn cols="4"  @click="editNote(item.id)" color="warning" class="mx-0">
                  Edytuj
                </v-btn>
                <v-btn cols="4" v-if="item.photoUrl" @click="downloadHistory(item.id)" color="secondary" class="mx-0">
                  Pobierz
                </v-btn>
                <v-btn  cols="4" color="error" @click="deleteHistory(item.id)" class="mx-0">
                  Usuń
                </v-btn>      
              </v-row>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
      <v-divider></v-divider>
      </v-card>
        <v-footer>
          <v-row>
            <v-col cols="6">
              <v-text-field label="Opis" v-model="historyDescription"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-file-input v-model="historyImg" type="file" refs="file" label="Plik"></v-file-input>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn class="primary" @click="saveHistory()"  style="width:100%;">
                Zapisz
              </v-btn>
            </v-col>
          </v-row>
        </v-footer>
    </v-dialog>   
    <v-card-title>      
      Flota      
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Szukaj" single-line hide-details></v-text-field>
      <v-spacer></v-spacer>
      <v-btn class="primary" @click="showDialog()">Dodaj</v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"      
      :items="cars"
      :options.sync="options"
      :server-items-length="totalRecords"
      :loading="loading"
      :search="search"
      class="elevation-1"
    >
          <template v-slot:item.actions="{ item }">
            <v-btn class="warning" @click="getCarById(item.id)">
              <v-icon small class="pt-1">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.history="{ item }">
            <v-btn class="primary" @click="showCarHistory(item.id)">
              <v-icon small class="pt-1">mdi-eye</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.dels="{ item }">
            <v-btn class="error" @click="deleteCar(item.id)">
              <v-icon small class="pt-1">mdi-delete</v-icon>
            </v-btn>
          </template>
  </v-data-table>
  </v-card>   
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
export default {
  name: "Cars",
  data: () => ({
        descr:[],
        descrEditable:[],
        historyDescription:'',  
        historyImg:null,
        search:'',
        advance: 0,
        rentPrice: 0,
        // tbl
        options: {},
        departamens: [],
        totalRecords: 0,
        headers: [
          {
            text: 'Marka',
            align: 'start',          
            value: 'make',
          },
          {
            text: 'Model',
            align: 'start',          
            value: 'model',
          },
          {
            text: 'Tablica rej.',
            align: 'start',          
            value: 'plate',
          },  
          {
            text: 'Przebieg',
            align: 'start',          
            value: 'odometer',
          },
          {
            text: 'Serwis Za',
            align: 'start',          
            value: 'lastService',
          },
          {
            text: 'OC do',
            align: 'start',          
            value: 'insuranceValidTo',
          },
          {
            text: 'Przegląd do',
            align: 'start',          
            value: 'inspectionValidTo',
          },
          {
            text: 'Licencja ?',
            align: 'start',
            value: 'hasLicence',
          }, 
          {
            text: 'Oklejony ?',
            align: 'start',
            value: 'isWrapped',
          },                                                                                
          { text: 'Główny Kierowca', value: 'driver' },
          { text: 'Drugi Kierowca', value: 'driver_second' },
          { text: '', value: 'actions',  sortable: false, },
          { text: '', value: 'history', sortable: false, },
          { text: '', value: 'dels', sortable: false, },
        ],
        cars: [],
        loading: false, 
        hasLicence:false,
        isWrapped:false,
        // create
        createDialog:false,
        make:'',
        model:'',
        year:'',
        odometer:'',
        taxiNo:'',
        color:'',
        plate: '',
        lastService:'',
        insuranceValidTo:'',
        inspectionValidTo:'',
        driver :'',
        driver_second: '',
        drivers:[],
        action:'Tworzenie',
        // history
        dialogHistory:false,
        historyTimeline: [],        
  }),
  watch: {
    options: {
      async handler() {
        await this.loadCars()
      },
      deep: true,
    },
    async search() {
      this.options.page = 1 
      await this.loadCars()
    }
  },  
  methods:{
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    }, 
    async deleteCar(id) {
      await this.toggleLoading(true);
      try {
        Swal.fire({
          title: "Usunąć Pojazd?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "/cars/delete-car/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.loadCars()
          }
        });
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
    },    
    async editNote(id){
      await this.toggleLoading(true);
      try {
        Swal.fire({
          title: "Zedytować ?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "patch",
              url: process.env.VUE_APP_ROOT_API + "/cars/update-note-car/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              data:{
                description: this.descr[id]
              }
            });
            await this.showCarHistory(this.currentCarId)
          }
        })
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);      
    },
    async downloadHistory(id){
      const file = await axios({
        method: "post",
        url: process.env.VUE_APP_ROOT_API + "/cars/download-file/" + id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),          
        },
        responseType:'blob'
      });
      let blob = new Blob([file.data], { type: file.data.type }),
      url = window.URL.createObjectURL(blob)
      window.open(url)             
    },
    async deleteHistory(id){
      await this.toggleLoading(true);
      try {
        Swal.fire({
          title: "Usunąć Wpis?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "/cars/delete-car-history/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.showCarHistory(this.currentCarId)
          }
        });
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);      
    } ,
    async saveHistory(){
      await this.toggleLoading(true);
      try{       
        await this.validateCarHistoryForm()       
        const formData = new FormData()    
        try{
          formData.append("historyImg", this.historyImg, this.historyImg.name);        
        }catch(e){
          console.log('Missing Image')
        }       
        
        formData.append('car', this.currentCarId)
        formData.append('description', this.historyDescription)
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "/cars/add-car-history",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "multipart/form-data"
          },
          data: formData
        })
        this.historyImg = null
        this.historyDescription = ''
        await this.showCarHistory(this.currentCarId)
      }catch(e){
        console.log(e);        
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
    },
    async showDialog() { 
      this.make = "";
      this.model = "";
      this.year = "";
      this.plate = "";
      this.odometer = ""
      this.taxiNo = ""
      this.color = ""
      this.lastService = "";
      this.insuranceValidTo = "";
      this.inspectionValidTo = "";
      this.advance = 0
      this.rentPrice = 0
      this.driver = null     
      this.driver_second = null  
      this.isWrapped = false;             
      this.action = 'Tworzenie'
      this.createDialog = true
      this.currentCarId = null
      this.hasLicence = false
      await this.getDriversSelect()
    },   
    async showCarHistory(id){
      this.currentCarId = id
      await this.toggleLoading(true);
      try {
        let history = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/cars/history/" + id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        history.data.data.forEach((item)=>{          
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
          this.descr[item.id] = item.description    
          this.descrEditable[item.id] = false       
        })
        this.historyTimeline = history.data.data  
      } catch (e) {
        console.log(e);
        this.dialogHistory = false;
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);      
      this.dialogHistory = true
    },
    async createCar() {
      if (!this.make.trim().length){
        Swal.fire("Błąd", 'Marka Samochodu nie może być pusta', "error");
        return false
      }
      if (!this.model.trim().length) {
        Swal.fire("Błąd", 'Model Samochodu nie może być pusty', "error");
        return false
      }  
      if (!this.year) {
        Swal.fire("Błąd", 'Model Samochodu nie może być pusty', "error");
        return false
      }            
      await this.toggleLoading(true);
      let method = "";
      let url = "";
      if (this.currentCarId) {
        method = "patch";
        url = process.env.VUE_APP_ROOT_API + "/cars/update-car/" + this.currentCarId;
      } else {
        method = "post";
        url = process.env.VUE_APP_ROOT_API + "/cars/create-car";
      }
      try {
        await this.validateCarForm()
        await axios({
          method: method,
          url: url,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            make: this.make,
            model: this.model,
            plate: this.plate,
            year: this.year,
            odometer: this.odometer,
            taxiNo: this.taxiNo,
            color: this.color,
            rentPrice: this.rentPrice,
            advance: this.advance,
            hasLicence: this.hasLicence,
            isWrapped: this.isWrapped,
            lastService: this.lastService?dayjs(this.lastService).format('YYYY-MM-DD'):'',
            insuranceValidTo: dayjs(this.insuranceValidTo).format('YYYY-MM-DD'),
            inspectionValidTo: dayjs(this.inspectionValidTo).format('YYYY-MM-DD'),
            driver: this.driver||null,
            driver_second: this.driver_second||null
          },
        });
        this.rentPrice = 0
        this.advance = 0
        this.hasLicence = false
        this.make = "";
        this.model = "";
        this.year = "";
        this.plate = "";
        this.odometer = ""
        this.taxiNo = ""
        this.color = ""
        this.lastService = "";
        this.insuranceValidTo = "";
        this.inspectionValidTo = "";
        this.driver = null
        this.driver_second = null
        this.createDialog = false
        this.currentCarId = null;
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
      await this.loadCars()
    },
    async validateCarHistoryForm(){
      if (!this.historyDescription.trim().length) {
        throw ({
          response: {
            statusText: 'Uzupełnij Opis'
          }
        })
      }
    },
    async validateCarForm(){      
      if(!this.make.trim().length){
        throw ({response:{
          statusText:'Uzupełnij Markę'
        }})
      }
      if (!this.model.trim().length){
        throw ({response:{
          statusText:'Uzupełnij Model'
        }})
      }
      if (!this.year){
        throw ({response:{
          statusText:'Uzupełnij Rok Produkcji'
        }})
      }
      if (!this.plate.trim().length){
        throw ({response:{
          statusText:'Uzupełnij Tablicę Rejestracyjną'
        }})
      }
      if (!this.odometer){
        throw ({response:{
          statusText:'Uzupełnij Przebieg'
        }})
      }
      if (!this.taxiNo){
        throw ({response:{
          statusText:'Uzupełnij Numer Boczny'
        }})
      }
      if (!this.advance) {
        throw ({
          response: {
            statusText: 'Uzupełnij Kaucję'
          }
        })
      }
      if (!this.rentPrice) {
        throw ({
          response: {
            statusText: 'Uzupełnij Koszt Najmu'
          }
        })
      }            
      if (!this.color.trim().length){
        throw ({response:{
          statusText:'Uzupełnij Kolor'
        }})
      }
      if (!this.insuranceValidTo.trim().length){
        throw ({response:{
          statusText:'Uzupełnij Datę Ubezpiczenia'
        }})
      }
      if (!this.inspectionValidTo.trim().length){
        throw ({response:{
          statusText:'Uzupełnij Datę Przeglądu'
        }})
      }
    },
    async getDriversSelect(){
      await this.toggleLoading(true);
      try{
        let drivers = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/drivers?itemsPerPage=10000&sort=lastName&direction="+false,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        drivers.data.data.items.unshift({
          id:null,
          driverName:'Brak Kierowcy'
        })
        drivers.data.data.items.forEach((item,i)=>{
          if(i>0){
            item.driverName = item.firstName + ' ' + item.lastName
          }          
        })
        this.drivers = drivers.data.data.items
      }catch(e){
        console.log(e);
        this.dialog = false;
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");        
      }
      await this.toggleLoading(false);
    },
    async getCarById(item) {
      await this.getDriversSelect()
      await this.toggleLoading(true);
      try {
        let car = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/cars/details/" + item,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.createDialog = true;
        this.action = "Edycja";
        this.currentCarId = item;
        this.make = car.data.data.make
        this.model = car.data.data.make
        this.advance = car.data.data.advance
        this.rentPrice = car.data.data.rentPrice/100
        this.plate = car.data.data.plate
        this.year = car.data.data.year
        this.odometer = car.data.data.odometer          
        this.taxiNo = car.data.data.taxiNo        
        this.color = car.data.data.color    
        this.isWrapped = car.data.data.isWrapped
        if (car.data.data.lastService){
          this.lastService = new Date(car.data.data.lastService)
            .toISOString()
            .substr(0, 10); 
        }                          
        this.insuranceValidTo = new Date(car.data.data.insuranceValidTo)
          .toISOString()
          .substr(0, 10);                               
        this.inspectionValidTo = new Date(car.data.data.inspectionValidTo)
          .toISOString()
          .substr(0, 10);                               
        this.driver = car.data.data.driver?car.data.data.driver.id:null   
        this.driver_second = car.data.data.driver_second ? car.data.data.driver_second.id : null                          
      } catch (e) {
        console.log(e);
        this.dialog = false;
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
    },    
    async loadCars() {
      this.loading = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.options
        const cars = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/cars/list-cars?sort=${sortBy.length ? sortBy : 'make'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}&s=${this.search}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        cars.data.data.items.forEach((item)=>{
          item.driver = item.driver? (item.driver.firstName + ' ' + item.driver.lastName):'Brak Kierowcy'
          item.driver_second = item.driver_second ? (item.driver_second.firstName + ' ' + item.driver_second.lastName) : 'Brak Kierowcy'
          item.inspectionValidTo = dayjs(item.inspectionValidTo).format('YYYY-MM-DD')
          item.lastService = item.lastService?dayjs(item.lastService).format('YYYY-MM-DD'):''
          item.insuranceValidTo = dayjs(item.insuranceValidTo).format('YYYY-MM-DD')
          item.hasLicence = item.hasLicence ? 'Tak':'Nie'
          item.isWrapped = item.isWrapped ? 'Tak' : 'Nie'
        })
        this.cars = cars.data.data.items
        this.itemsPerPage = cars.data.data.meta.itemsPerPage
        this.page = cars.data.data.meta.currentPage
        this.totalRecords = cars.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.loading = false
    },      
  }
}
</script>
